// helper to extract the hook-form / yup errors in forms
type ErrorsObject = {
  [key: string]:
    | {
        message?: string;
      }
    | undefined;
};

export function getErrorMessage(
  errors: ErrorsObject,
  fieldName: string,
): string | undefined {
  const fieldError = errors[fieldName];
  return typeof fieldError?.message === 'string'
    ? fieldError.message
    : undefined;
}
