import Parse from 'parse';
import getEventConnectionDataApi from '../api/event/getEventConnectionDataApi';
import usePlayerStore from '../store/playerStore';

type setConfigProps = { eventId: string };

/**
 * Bootstrap the app with the data for the provided event
 * @param eventId The eventId (Main Server Event Id) of the event
 *
 * @returns
 */
export default async function setConfig(params: setConfigProps) {
  // props
  const { eventId } = params;

  //// get the events connection data
  const playerData = await getEventConnectionDataApi({ eventId: eventId });

  // update the store
  usePlayerStore.setState({ connectionData: playerData.result.connectionData });
  usePlayerStore.setState({ eventId: playerData.result.ids });

  //// initialize Parse SDK
  Parse.initialize(
    playerData.result.connectionData.appId,
    playerData.result.connectionData.javascriptKey,
  );
  Parse.serverURL = playerData.result.connectionData.serverUrl;

  // check if there is an existing user in local store
  const localUserData = localStorage.getItem(
    'bsLocalUser-' + playerData.result.ids.eventId,
  );

  if (localUserData)
    usePlayerStore.setState({ playerUser: JSON.parse(localUserData) });

  // return 'no error' code
  return playerData;
}
