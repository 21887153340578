import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // partialBundledLanguages: true,
    defaultNS: ['common'],
    fallbackNS: 'default',
    fallbackLng: 'de',
    // lng: 'en', // if you're using a language detector, do not define the lng option
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath:
        process.env.REACT_APP_PUBLIC_URL + '/locale/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
