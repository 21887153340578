// import ActorConfirmed from '-!svg-react-loader!../svg/ActorList/actorConfirmed.svg';
import EventCelebration from '../svg/EventTypeIcons/celebration.svg';
import { createSvgIcon } from '@mui/material';
import React from 'react';

const props = {
  version: '1.1',
  width: '24px',
  height: '24px',
  viewBox: '0 0 512 512',
};

export const EventCelebrationIcon = createSvgIcon(
  <EventCelebration {...(props as any)} />,
  'Trim',
);
