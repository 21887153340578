import { Box, Typography, useTheme } from '@mui/material';
import usePlayerStore from '../store/playerStore';
import rewriteHtml from '../util/htmlTextHelpers';
import useAgenda from '../hooks/useAgenda';

export default function PlayerContentAgenda() {
  // global store
  const { playerUi, previewAgendaSessionId } = usePlayerStore();

  // theme
  const theme = useTheme();

  // get Agenda
  // const Agenda = useAgenda({ stage: 'preview' });
  const { renderProgram, renderAgenda } = useAgenda({ stage: 'preview' });

  return (
    <Box
      width="100%"
      sx={{
        // backgroundColor: playerUi.bgColor,
        backgroundColor: theme.palette.playerBgColor.main,
        color: theme.palette.playerBgColor.contrastText,
      }}
    >
      {/* <Agenda /> */}
      {
        <Box sx={{ mb: 0 }}>
          {/* {playerUi.multiSession ? renderProgram() : renderAgenda()} */}
          {renderAgenda(previewAgendaSessionId)}
        </Box>
      }
    </Box>
  );
}
