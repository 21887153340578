import { Box } from '@mui/material';
import usePlayerStore from '../../store/playerStore';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AccessCodeFormLayout from './AccessCodeFormLayout';
import {
  accessCodeDefaults,
  accessCodeValSchema,
} from './accessCodeValidationSchema';
import { AccessCodeFormTypes } from './accessCodeFormTypes';
import { useEventPlayerUserByAccessTokenQuery } from '../../data/user.query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import getEventPlayerUserByAccessTokenApi from '../../api/user/getEventPlayerUserByAccessTokenApi';
import { useEffect } from 'react';
import { useFeedback } from '../Feedback/FeedbackContext';
import { log } from 'console';
import confirmEventPlayerUserApi from '../../api/user/confirmEventPlayerUserApi';

export default function AccessCodeForm() {
  // global store
  const {
    eventId,
    playerUser,
    setPlayerUser,
    registrationData,
    onlineStageData,
    isPreview,
  } = usePlayerStore();

  // feedback
  const { setFeedback } = useFeedback();

  // get user
  const queryClient = useQueryClient();

  // const {
  //   data: userData,
  //   error,
  //   isLoading,
  //   isSuccess,
  //   isError,
  //   status,
  // } = useEventPlayerUserByAccessTokenQuery(eventId, playerUser?.uct);

  // console.log('userData', userData);

  // data mutation // confirm user
  const confirmEventPlayerUser = useMutation({
    mutationFn: (data: any) => confirmEventPlayerUserApi(data),
    onSuccess: (data, variables, context) => {
      if (data) {
        localStorage.setItem(
          'bsLocalUser-' + eventId!.eventId,
          JSON.stringify(data),
        );
        setPlayerUser(data.attributes);
      } else {
      }
    },
    onError: (error, variables, context) => {},
  });

  // react hook form
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    defaultValues: accessCodeDefaults,
    resolver: yupResolver(accessCodeValSchema),
  });

  // functions
  const handleFormSubmit = async (data: AccessCodeFormTypes) => {
    console.log('data', data);

    if (!isPreview) {
      // get user
      const user = queryClient
        .fetchQuery({
          queryFn: () =>
            getEventPlayerUserByAccessTokenApi({
              eventId: eventId,
              personalAccessCode: data.personalAccessCode,
            }),
          queryKey: ['player', 'user'],
        })
        .then((res) => {
          console.log('res', res);
          confirmEventPlayerUser.mutate({
            data: res.attributes,
            eventId: eventId,
            personalAccessCode: data.personalAccessCode,
          });
        })
        .catch((e) => {
          console.error(e);
          setFeedback({ type: 'error', errorCode: e });
        });
    }
  };

  // // side effects
  // useEffect(() => {
  //   console.log('isSuccess', isSuccess);
  //   if (isSuccess) {
  //   }
  // }, [isSuccess]);

  // useEffect(() => {
  //   console.log('isError', isError);

  //   if (isError) {
  //     setFeedback({ type: 'error', errorCode: error });
  //   }
  // }, [isError]);

  return (
    <>
      <AccessCodeFormLayout
        control={control}
        handleFormSubmit={handleFormSubmit}
        handleSubmit={handleSubmit}
      />
    </>
  );
}
