import { Theme } from '@mui/material';

export default function MuiAppBar(theme: Theme) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.navigationBg.main,
          backgroundImage: 'none',
        },
      },
    },
  };
}
