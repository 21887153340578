import { useEffect, useRef } from 'react';

export default function useInterval(
  callback: () => void,
  delay: number | null,
) {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };

    if (typeof delay === 'number') {
      const id = setInterval(tick, delay);
      intervalRef.current = id;
      return () => clearInterval(intervalRef.current as NodeJS.Timeout);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [delay]);

  return intervalRef;
}
