import { Theme } from '@mui/material';

export default function MuiButton(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          // textTransform: 'none',
          // fontSize: theme.typography.body2.fontSize,
          // paddingTop: '8px',
          // paddingBottom: '8px',
          minHeight: 0,
        },
      },
    },
  };
}
