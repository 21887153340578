import Parse from 'parse';

export default async function setEventPlayerUserApi(data: any) {
  console.log('#-setEventPlayerUserApi-data', data);

  // remove fieldDefinitions, previously added for dynamic validation
  delete data.data.fieldDefinition;
  delete data.data.accessCodeRequired;

  return Parse.Cloud.run('setEventPlayerUser', data);
}
