// function hexToRgb(hex: string): { r: number; g: number; b: number } {
//   const bigint = parseInt(hex.slice(1), 16);
//   const r = (bigint >> 16) & 255;
//   const g = (bigint >> 8) & 255;
//   const b = bigint & 255;
//   return { r, g, b };
// }

// function rgbToHex(r: number, g: number, b: number): string {
//   return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
// }

// function adjustBrightness(
//   { r, g, b }: { r: number; g: number; b: number },
//   percentage: number,
// ): { r: number; g: number; b: number } {
//   const factor = 1 + percentage / 100;
//   return {
//     r: Math.min(255, Math.max(0, Math.round(r * factor))),
//     g: Math.min(255, Math.max(0, Math.round(g * factor))),
//     b: Math.min(255, Math.max(0, Math.round(b * factor))),
//   };
// }

// function luminance(r: number, g: number, b: number): number {
//   const a = [r, g, b].map((v) => {
//     v /= 255;
//     return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
//   });
//   return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
// }

// export function adjustColorBasedOnBrightness(hex: string): string {
//   const rgb = hexToRgb(hex);
//   const brightness = luminance(rgb.r, rgb.g, rgb.b);
//   const adjustment = brightness >= 0.5 ? -20 : 20;
//   const adjustedRgb = adjustBrightness(rgb, adjustment);

//   console.log(
//     '##resultColor##',
//     rgbToHex(adjustedRgb.r, adjustedRgb.g, adjustedRgb.b),
//   );

//   return rgbToHex(adjustedRgb.r, adjustedRgb.g, adjustedRgb.b);
// }

function hexToRgb(hex: string): { r: number; g: number; b: number } {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

function rgbToHex(r: number, g: number, b: number): string {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}

function adjustBrightness(value: number, percentage: number): number {
  const factor = 1 + percentage / 100;
  return Math.min(255, Math.max(0, Math.round(value * factor)));
}

function luminance(r: number, g: number, b: number): number {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function adjustGrayBasedOnBrightness(hex: string): string {
  const { r, g, b } = hexToRgb(hex);
  const brightness = luminance(r, g, b);

  if (r <= 16 && g <= 16 && b <= 16) {
    return '#393939';
  }

  let adjustment;
  if (brightness >= 0.5) {
    adjustment = -20;
  } else {
    // Progressive adjustment: the darker the color, the higher the percentage to lighten
    adjustment = 60 + (0.5 - brightness) * 400;
  }

  const avg = (r + g + b) / 3;
  const adjustedValue = adjustBrightness(avg, adjustment);
  const grayValue = Math.round(adjustedValue);
  console.log('outColor', rgbToHex(grayValue, grayValue, grayValue));

  return rgbToHex(grayValue, grayValue, grayValue);
}
