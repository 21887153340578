import { Button, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import usePlayerStore from '../store/playerStore';
import { useTranslation } from 'react-i18next';
import LegalModal from './LegalModal';
import { startTransition, useState } from 'react';

export default function PlayerFooter() {
  // eventUiData
  const { playerUi } = usePlayerStore();

  // translation
  const { t } = useTranslation('footer');

  // mediaQuery
  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  // state
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen((state) => !state);
  };

  const [content, setContent] = useState('');
  const [headline, setHeadline] = useState('');

  const openContent = (topic: string, headline: string) => {
    startTransition(() => {
      setContent(topic);
      setHeadline(headline);
      toggleOpen();
    });
  };

  return (
    <>
      <Stack
        id="bs_Player_Header"
        // direction={mediaQuery ? 'column' : 'row'}
        direction="row"
        spacing={mediaQuery ? 0 : 2}
        sx={{
          minHeight: '60px',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {playerUi!.displayLegalText && (
          <Button
            variant="text"
            onClick={() =>
              openContent(playerUi!.legalText, 'Datenschutzerklärung')
            }
            sx={{ textTransform: 'none', py: 0 }}
          >
            {t('MENU.ITEM_legal')}
          </Button>
        )}

        {playerUi!.displayImprintText && (
          <Button
            variant="text"
            onClick={() => openContent(playerUi!.imprintText, 'Impressum')}
            sx={{ textTransform: 'none', py: 0 }}
          >
            {t('MENU.ITEM_imprint')}
          </Button>
        )}

        {playerUi!.displayCookiesText && (
          <Button
            variant="text"
            onClick={() => openContent(playerUi!.cookiesText, 'Cookies')}
            sx={{ textTransform: 'none', py: 0 }}
          >
            {t('MENU.ITEM_cookies')}
          </Button>
        )}

        {playerUi!.displayContactText && (
          <Button
            variant="text"
            onClick={() => openContent(playerUi!.contactText, 'Kontakt')}
            sx={{ textTransform: 'none', py: 0 }}
          >
            {t('MENU.ITEM_contact')}
          </Button>
        )}
      </Stack>

      <LegalModal
        open={open}
        closeHandler={toggleOpen}
        content={content}
        headline={headline}
      />
    </>
  );
}
