import { ThemeOptions, useTheme } from '@mui/material';
import {
  amber,
  blue,
  red,
  orange,
  lightBlue,
  green,
} from '@mui/material/colors';

export const themeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: 'dark',
    divider: 'rgba(255,255,255,0.12)',
    primary: {
      main: amber[600],
      dark: amber[700],
      light: amber[500],
      contrastText: 'rgba(0,0,0,0.87)',
    },
    secondary: {
      main: blue[200],
      dark: blue[400],
      light: blue[50],
      contrastText: 'rgba(0,0,0,0.87)',
    },
    text: {
      primary: 'rgba(255,255,255,1)',
      secondary: 'rgba(255,255,255,0.8)',
      disabled: 'rgba(255,255,255,0.5)',
    },
    error: {
      main: red[500],
      dark: red[700],
      light: red[300],
      contrastText: 'rgba(255,255,255,1)',
    },
    warning: {
      main: orange[700],
      dark: orange[900],
      light: orange[500],
      contrastText: 'rgba(0,0,0,1)',
    },
    info: {
      main: lightBlue[400],
      dark: lightBlue[700],
      light: lightBlue[300],
      contrastText: 'rgba(0,0,0,1)',
    },
    success: {
      main: green[400],
      dark: green[700],
      light: green[300],
      contrastText: 'rgba(0,0,0,1)',
    },
    action: { active: 'rgba(255,255,255,0.7)' },
    // background: { default: '#262626' },
    background: { default: '#333' },
    navigationBg: { main: '#121212' },
    overlayBorder: { main: 'rgba(255,255,255,0.5)' },
    // slotBg: { main: '#383838' },
    slotBg: { main: '#1E1E1E' },
    slotOverlay: { main: '#fff' },
    slotBorder: { main: 'rgba(255,255,255,0.5)' },
    neutral: { main: '#808080', contrastText: '#fff' },
  },
  typography: {
    //   // fontFamily:
    //   //   'Inter, -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    h1: {
      fontWeight: 300,
      fontSize: '2rem',
      lineHeight: 1.167,
      letterSpacing: '-0.047em',
    },
    h2: {
      fontWeight: 300,
      fontSize: '1.75rem',
      lineHeight: 1.2,
      letterSpacing: '-0.015em',
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: 1.235,
      letterSpacing: '0.009em',
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.125rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.6,
      letterSpacing: '0.005em',
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.2,
      letterSpacing: '0.005em',
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.0052em',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.2,
      letterSpacing: '0.01em',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: 1.2,
      letterSpacing: '0em',
    },
    overline: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.005em',
    },
    caption: {
      fontWeight: 300,
      fontSize: '0.75rem',
      lineHeight: 1.2,
      letterSpacing: '0.035em',
    },
  },
};
