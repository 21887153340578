import Trim from '../svg/Slotlist/trim.svg';
import { createSvgIcon } from '@mui/material';
import React from 'react';

const props = {
  version: '1.1',
  width: '24px',
  height: '24px',
  viewBox: '0 0 512 512',
};

export const TrimIcon = createSvgIcon(<Trim {...(props as any)} />, 'Trim');
