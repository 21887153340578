import { Box, Typography } from '@mui/material';
import parse, {
  domToReact,
  HTMLReactParserOptions,
  Element as DomElement,
  DOMNode,
} from 'html-react-parser';

import usePlayerStore from '../store/playerStore';
import Countdown from '../Components/Countdown/Countdown';
// import ReactHtmlParser, {
//   Transform,
//   convertNodeToElement,
// } from 'react-html-parser';

export default function rewriteHtml(htmlText: string) {
  console.log('rawHTML', htmlText);

  if (htmlText && htmlText !== '') {
    htmlText = parseVariables(htmlText);
    console.log('variableHTML', htmlText);
    const parsedHtml = parse(htmlText, options);

    return parsedHtml;
  }
  return '';
}

export function parseVariables(htmlText: string): string {
  const isPreview = usePlayerStore.getState().isPreview;
  // replace firstname
  htmlText = htmlText.replaceAll(
    '{#firstname#}',
    usePlayerStore.getState().playerUser?.firstname
      ? usePlayerStore.getState().playerUser!.firstname
      : isPreview
        ? 'Max'
        : '',
  );

  // replace lastname
  htmlText = htmlText.replaceAll(
    '{#lastname#}',
    usePlayerStore.getState().playerUser?.lastname
      ? usePlayerStore.getState().playerUser!.lastname
      : isPreview
        ? 'Mustermann'
        : '',
  );

  htmlText = htmlText.replaceAll(
    '{#Countdown - anderer Text in diesem Absatz wird ignoriert#}',
    '',
  );

  return htmlText;
}

function styleStringToObject(styleString: string) {
  return styleString
    .split(';')
    .reduce((acc: { [key: string]: string }, style) => {
      if (style.trim()) {
        const [key, value] = style.split(':');
        if (key && value) {
          acc[key.trim()] = value.trim();
        }
      }
      return acc;
    }, {});
}

const options: HTMLReactParserOptions = {
  replace: (node: DOMNode) => {
    if (node instanceof DomElement) {
      const { name, children, attribs } = node;
      const childrenArray = children as DOMNode[];
      let textAlign: 'left' | 'right' | 'center' | undefined;
      let marginLeft: string | undefined;
      let pt: string | undefined;

      if (name === 'div' && attribs && attribs.id === 'bs_Player_Countdown') {
        const style = attribs.style
          ? styleStringToObject(attribs.style)
          : undefined;

        // Behalte das div bei und füge die PlayerCountdown-Komponente als Kind hinzu
        return (
          <div id="bs_Player_Countdown" style={style} key={attribs?.key}>
            <Countdown
              targetDate={
                usePlayerStore.getState().playerUi!.eventStartDateTime
              }
            />
          </div>
        );
      }

      if (name === 'p') {
        pt = '1rem';
      }
      if (attribs && attribs.style) {
        const style = attribs.style;
        if (style.includes('text-align: left;')) textAlign = 'left';
        if (style.includes('text-align: right;')) textAlign = 'right';
        if (style.includes('text-align: center;')) textAlign = 'center';
        const marginLeftMatch = style.match(/margin-left:\s*(\d+px);?/);
        if (marginLeftMatch) {
          marginLeft = marginLeftMatch[1];
        }
      }

      const commonProps = {
        textAlign,
        sx: { marginLeft, pt },
        key: attribs?.key,
      };

      switch (name) {
        case 'h1':
          return (
            <Typography variant="h1" {...commonProps}>
              {domToReact(childrenArray, options)}
            </Typography>
          );
        case 'h2':
          return (
            <Typography variant="h2" {...commonProps}>
              {domToReact(childrenArray, options)}
            </Typography>
          );
        case 'h3':
          return (
            <Typography variant="h3" {...commonProps}>
              {domToReact(childrenArray, options)}
            </Typography>
          );
        case 'p':
          return (
            // <Box {...commonProps}>
            <Typography variant="body1" {...commonProps}>
              {domToReact(childrenArray, options)}
            </Typography>
            // </Box>
          );
        case 'em':
          return (
            <Typography
              component="span"
              sx={{ fontStyle: 'italic', marginLeft }}
              key={attribs?.key}
            >
              {domToReact(childrenArray, options)}
            </Typography>
          );
        case 'strong':
          return (
            <Typography
              component="span"
              sx={{ fontWeight: 'bold', marginLeft }}
              key={attribs?.key}
            >
              {domToReact(childrenArray, options)}
            </Typography>
          );
        case 'u':
          return (
            <Typography
              component="span"
              sx={{ textDecoration: 'underline', marginLeft }}
              key={attribs?.key}
            >
              {domToReact(childrenArray, options)}
            </Typography>
          );
        default:
          return null;
      }
    }
  },
};

// const transform: Transform = (node: any, index: number) => {
//   if (node.type === 'tag') {
//     const { name, children, attribs } = node;
//     switch (name) {
//       case 'h1':
//         return (
//           <Typography variant="h1" key={index}>
//             {children &&
//               children.map((childNode: any, childIndex: number) =>
//                 convertNodeToElement(childNode, childIndex, transform),
//               )}
//           </Typography>
//         );
//       case 'h2':
//         return (
//           <Typography variant="h2" key={index}>
//             {children &&
//               children.map((childNode: any, childIndex: number) =>
//                 convertNodeToElement(childNode, childIndex, transform),
//               )}
//           </Typography>
//         );
//       case 'h3':
//         return (
//           <Typography variant="h3" key={index}>
//             {children &&
//               children.map((childNode: any, childIndex: number) =>
//                 convertNodeToElement(childNode, childIndex, transform),
//               )}
//           </Typography>
//         );
//       case 'p':
//         let textAlign: 'left' | 'right' | 'center' | undefined;
//         if (attribs.style) {
//           const style = attribs.style;
//           if (style.includes('text-align: left;')) textAlign = 'left';
//           if (style.includes('text-align: right;')) textAlign = 'right';
//           if (style.includes('text-align: center;')) textAlign = 'center';
//         }
//         return (
//           <Box textAlign={textAlign} key={index}>
//             <Typography>
//               {children &&
//                 children.map((childNode: any, childIndex: number) =>
//                   convertNodeToElement(childNode, childIndex, transform),
//                 )}
//             </Typography>
//           </Box>
//         );
//       case 'em':
//         return (
//           <Typography component="span" sx={{ fontStyle: 'italic' }} key={index}>
//             {children &&
//               children.map((childNode: any, childIndex: number) =>
//                 convertNodeToElement(childNode, childIndex, transform),
//               )}
//           </Typography>
//         );
//       case 'strong':
//         return (
//           <Typography component="span" sx={{ fontWeight: 'bold' }} key={index}>
//             {children &&
//               children.map((childNode: any, childIndex: number) =>
//                 convertNodeToElement(childNode, childIndex, transform),
//               )}
//           </Typography>
//         );
//       case 'u':
//         return (
//           <Typography
//             component="span"
//             sx={{ textDecoration: 'underline' }}
//             key={index}
//           >
//             {children &&
//               children.map((childNode: any, childIndex: number) =>
//                 convertNodeToElement(childNode, childIndex, transform),
//               )}
//           </Typography>
//         );
//       default:
//         return convertNodeToElement(node, index, transform);
//     }
//   }
//   return null;
// };
