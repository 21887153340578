import { Theme } from '@mui/material';

export default function MuiDrawer(theme: Theme) {
  return {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: theme.palette.navigationBg.main,
          // backgroundImage: 'none',
          // backgroundColor: 'red',
        },
      },
    },
  };
}
