import usePlayerStore from '../../store/playerStore';

type MediaEmbedSlidoProps = {
  code: string;
};

export default function MediaEmbedSlido(props: MediaEmbedSlidoProps) {
  // props
  const { code } = props;

  const { playerUser } = usePlayerStore();

  // // get the user data from the eventSession token !!! DON'T !!!
  // let sessionUserData;

  // if (config.eventSession)
  //   sessionUserData = jwtDecode(config.eventSession).userData;

  // const userName = sessionUserData?.first_name
  //   ? 'user_name=' +
  //     sessionUserData.first_name +
  //     ' ' +
  //     sessionUserData.last_name +
  //     '&'
  //   : '';
  // const userMail = sessionUserData?.email
  //   ? 'user_email=' + sessionUserData.email + '&'
  //   : '';
  // const userOrganisation = sessionUserData?.organisation
  //   ? 'user_company=' + sessionUserData.organisation
  //   : '';

  console.log('playerUser', playerUser);

  const userName = playerUser?.firstname
    ? 'user_name=' + playerUser.firstname + ' ' + playerUser.lastname + '&'
    : '';
  const userMail = playerUser?.email
    ? 'user_email=' + playerUser.email + '&'
    : '';
  // const userOrganisation = sessionUserData?.organisation
  //   ? 'user_company=' + sessionUserData.organisation
  //   : '';

  console.log('userName', userName);
  console.log('userMail', userMail);

  console.log(
    uriEncode(
      code + '?' + userName + userMail,
      //   +userOrganisation,
    ),
  );

  return (
    <>
      <iframe
        title="Slido"
        allowFullScreen={true}
        src={uriEncode(
          code + '?' + userName + userMail,
          //   +userOrganisation,
        )}
        height="100%"
        width="100%"
        style={{
          border: 'none',
          minHeight: '600px',
        }}
      />
      ;
    </>
  );
}

function uriEncode(str: string) {
  return encodeURI(str).replace(/%5B/g, '[').replace(/%5D/g, ']');
}
