import { Theme } from '@mui/material';

export default function MuiDialog(theme: Theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        paper: { border: `1px solid ${theme.palette.overlayBorder.main}` },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '20px',
          fontWeight: '500',
          padding: '16px 20px 0px 20px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '10px 20px 0px 20px',
        },
      },
    },
  };
}
