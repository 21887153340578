import { Box, useTheme } from '@mui/material';
import usePlayerStore from '../store/playerStore';
import useAgenda from '../hooks/useAgenda';
import useLiveStore from '../store/liveStore';
import rewriteHtml from '../util/htmlTextHelpers';

export default function PlayerContentSessionWelcome() {
  // global store
  const { selectedSession, isPreview, previewSession } = usePlayerStore();

  // select the session to display
  const sessionToDisplay = isPreview ? previewSession! : selectedSession!;

  const sessionLiveData = useLiveStore(
    (state) => state.sessions[sessionToDisplay],
  );

  // theme
  const theme = useTheme();

  // get Agenda
  const { renderProgram, renderAgenda } = useAgenda({ stage: 'live' });

  return (
    <Box
      width="100%"
      pt={1}
      sx={{ color: theme.palette.playerBgColor.contrastText }}
    >
      {sessionLiveData?.sceneData?.general?.showDescriptionOnSessionWelcome &&
        rewriteHtml(sessionLiveData?.sceneData?.general?.sessionDescription)}

      {sessionLiveData?.sceneData?.general?.showAgendaOnSessionWelcome && (
        <Box sx={{ mb: 0 }}>{renderAgenda(sessionLiveData.id)}</Box>
      )}
    </Box>
  );
}
