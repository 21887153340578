import Parse from 'parse';

type getEventPlayerUiApiParams = {
  eventId: string;
  agendaPreview?: boolean;
};

export default async function getEventPlayerUiApi(
  params: getEventPlayerUiApiParams,
) {
  // params
  const { eventId, agendaPreview } = params;

  const playerUiData = await Parse.Cloud.run('getEventPlayerUi', {
    eventId: eventId,
    agendaPreview: agendaPreview,
  }).catch((e) => {
    console.log(e);
    throw new Error(e);
  });

  return playerUiData;
}
