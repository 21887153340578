import { Box, Typography, useTheme } from '@mui/material';
import usePlayerStore from '../store/playerStore';
import rewriteHtml from '../util/htmlTextHelpers';
import useAgenda from '../hooks/useAgenda';
import Countdown from './Countdown/Countdown';

export default function PlayerContentWelcome() {
  // global store
  const { playerUi, registrationData } = usePlayerStore();

  const {
    showEventTextWelcome,
    showAgendaWelcome,
    showWelcomeTextWelcome,
    showCountDownWelcome,
  } = registrationData!;

  // theme
  const theme = useTheme();

  // get Agenda
  const { renderProgram, renderAgenda } = useAgenda({ stage: 'marketing' });

  return (
    <Box
      width="100%"
      pt={1}
      sx={{ color: theme.palette.playerBgColor.contrastText }}
    >
      {showEventTextWelcome && rewriteHtml(playerUi!.descriptionHtml)}

      {showWelcomeTextWelcome && rewriteHtml(registrationData!.welcomeText)}

      {/* {showCountDownWelcome && (
        <Countdown targetDate={playerUi!.eventStartDateTime} />
      )} */}

      {showAgendaWelcome && (
        <Box sx={{ mb: 0 }}>
          {playerUi!.multiSession ? renderProgram() : renderAgenda()}
        </Box>
      )}
    </Box>
  );
}
