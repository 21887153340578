import { lazy, useEffect, useState } from 'react';
import usePlayerStore from '../store/playerStore';
import useInterval from './useInterval';
import useLiveStore from '../store/liveStore';
import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import MediaEmbedNormal from '../Components/MediaEmbed/MediaEmbedNormal';
import MediaEmbedSlido from '../Components/MediaEmbed/MediaEmbedSlido';
import MediaEmbedPlayer from '../Components/MediaEmbed/MediaEmbedPlayer';
import useAgenda from './useAgenda';

export default function useLivePlayerControl() {
  // params

  // state
  const [sceneToDisplay, setSceneToDisplay] = useState(<></>);

  // global store
  // const { liveDataSession } = useLiveStore();
  const { playerUi, isPreview, selectedSession, previewSession } =
    usePlayerStore();

  // select the session to display
  const sessionToDisplay = isPreview ? previewSession! : selectedSession!;

  const curLiveData = useLiveStore((state) =>
    playerUi!.multiSession
      ? state.sessions[sessionToDisplay]
      : Object.values(state.sessions)[0],
  );

  console.log('###!!!', curLiveData, selectedSession);

  // let curLiveData: any;

  // if (playerUi.multiSession) {
  //   curLiveData = liveDataSession.find(
  //     (ses: any) => ses.id === selectedSession,
  //   );
  // } else {
  //   curLiveData = liveDataSession[0];
  // }

  // console.log('##!##!#-curLiveData', curLiveData);

  //// sideEffects
  useEffect(() => {
    // if (
    //   liveDataSession?.some((ses: any) => ses.status === 'play') ||
    //   (liveDataSession && liveDataSession.length !== 0 && isPreview)
    // )
    if (curLiveData?.status === 'play' || (curLiveData && isPreview)) {
      switch (curLiveData.sceneData.layout) {
        case 1:
          setSceneToDisplay(
            <Layout_1
              id={curLiveData.id}
              {...curLiveData.sceneData.widgets}
              {...curLiveData.sceneData.general}
            />,
          );
          break;
        default:
          setSceneToDisplay(
            <Layout_0
              id={curLiveData.id}
              {...curLiveData.sceneData.widgets}
              {...curLiveData.sceneData.general}
            />,
          );
      }
    }
  }, [curLiveData, isPreview]);

  return { sceneToDisplay: sceneToDisplay };
}

type Layout_0Props = {
  id: string;
  '0': { type: string; data: { code: string } };
  showDescriptionOnSessionBroadcast: boolean;
  showAgendaOnSessionBroadcast: boolean;
  sessionDescription: string;
};

function Layout_0(props: Layout_0Props) {
  //
  console.log('##!##!#-props', props);

  const [fullscreenActive, setFullscreenActive] = useState(false);

  const { renderProgram, renderAgenda } = useAgenda({
    stage: 'marketing',
    variant: 'Widget',
  });

  const resizeCheck = () => {
    console.log('Callback fired!!!');

    if (
      !document.fullscreenElement &&
      !document.webkitCurrentFullScreenElement
    ) {
      console.log('not fullscreen');
      document.getElementById('container')!.style.transform = 'unset';
      setFullscreenActive(false);
    } else {
      console.log('fullscreen');
      document.getElementById('container')!.style.transform =
        'translateY(calc(50vh - 50%))';
      setFullscreenActive(true);
    }
  };

  useEffect(() => {
    // get the correct sizes on first load
    // resizeContainer();
    resizeCheck();

    // add the resize event handlers

    // window.addEventListener('resize', resizeContainer, false);

    document.addEventListener('fullscreenchange', resizeCheck, false);
    document.addEventListener('mozfullscreenchange', resizeCheck, false);
    document.addEventListener('MSFullscreenChange', resizeCheck, false);
    document.addEventListener('webkitfullscreenchange', resizeCheck, false);
    // document.addEventListener('webkitendfullscreen', resizeCheck, false);

    return () => {
      // remove the eventhandlers on unmount
      // window.removeEventListener('resize', resizeContainer, false);

      document.removeEventListener('fullscreenchange', resizeCheck, false);
      document.removeEventListener('mozfullscreenchange', resizeCheck, false);
      document.removeEventListener('MSFullscreenChange', resizeCheck, false);
      document.removeEventListener(
        'webkitfullscreenchange',
        resizeCheck,
        false,
      );
      // document.removeEventListener('webkitendfullscreen', resizeCheck, false);
    };
  }, []);

  return (
    <>
      {/* {props.showDescriptionOnSessionBroadcast && (
        <Typography
          sx={{
            p: {
              md: 2,
            },
            pb: { md: 0 },
          }}
        >
          {props.sessionDescription}
        </Typography>
      )} */}
      <Box
        id="container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: fullscreenActive ? 'unset' : 2,
        }}
      >
        {props[0]?.data.code && <MediaEmbedPlayer code={props[0].data.code} />}
      </Box>
      {/* {props.showAgendaOnSessionBroadcast && renderAgenda(props.id)} */}
    </>
  );
}

type Layout_1Props = {
  id: string;
  '0': { type: string; data: { code: string } };
  '1': { type: string; data: { code: string } };
  '2': { type: string; data: { code: string } };
  // agendaHeadline: string;
  // displayAgenda: boolean;
  // displaySessionText: boolean;
  // sessionDescription: string;
  showDescriptionOnSessionBroadcast: boolean;
  showAgendaOnSessionBroadcast: boolean;
  sessionDescription: string;
};
function Layout_1(props: Layout_1Props) {
  console.log('liveLayoutProps', props);

  // theme
  const theme = useTheme();

  // get Agenda
  // const Agenda = useAgenda({ stage: 'marketing', variant: 'Widget' });
  const { renderProgram, renderAgenda } = useAgenda({
    stage: 'live',
    variant: 'Widget',
  });

  const Frame = styled(Box)({
    border: '1px solid',
    borderColor: theme.palette.grey[800],
    borderRadius: '16px',
    height: '100%',
  });

  let containerHeight = '200px';
  let slot1Height = '200px';
  let slot2Height = '200px';

  const resizeContainer = () => {
    const containerElement = document.getElementById('container');
    const width = containerElement!.getBoundingClientRect().width;
    const height = (width / 16) * 9;
    containerHeight = height + 'px';

    const slot1Element = document.getElementById('slot1');
    const slot2ContainerElement = document.getElementById('slot2Container');
    const widthS = slot1Element!.getBoundingClientRect().width;
    const heightS = (widthS / 16) * 9;

    slot1Height = heightS + 'px';
    slot2Height = height - heightS - 32 + 'px';

    slot1Element!.style.height = slot1Height;

    if (window.innerWidth >= theme.breakpoints.values.md) {
      containerElement!.style.height = containerHeight;
      slot2ContainerElement!.style.height = slot2Height;
    } else {
      containerElement!.style.height = 'auto';
      slot2ContainerElement!.style.height = 'auto';
    }
  };

  const resizeWithCheck = () => {
    if (
      !document.fullscreenElement &&
      !(document as any).webkitCurrentFullScreenElement
    ) {
      // document.getElementById("container").style.marginTop = "unset";
      document.getElementById('container')!.style.transform = 'unset';
      document.getElementById('leftContainer')!.style.flex =
        window.innerWidth >= theme.breakpoints.values.md ? '2 1 0' : '1 1 0';
    } else {
      // document.getElementById("container").style.marginTop = "50%";
      document.getElementById('container')!.style.transform =
        'translateY(calc(50vh - 50%))';
      document.getElementById('leftContainer')!.style.flex =
        window.innerWidth >= theme.breakpoints.values.md ? '3 1 0' : '2 1 0';
    }

    resizeContainer();
  };

  useEffect(() => {
    // get the correct sizes on first load
    // resizeContainer();
    resizeWithCheck();

    // add the resize event handlers

    window.addEventListener('resize', resizeContainer, false);

    document.addEventListener('fullscreenchange', resizeWithCheck, false);
    document.addEventListener('mozfullscreenchange', resizeWithCheck, false);
    document.addEventListener('MSFullscreenChange', resizeWithCheck, false);
    document.addEventListener('webkitfullscreenchange', resizeWithCheck, false);

    return () => {
      // remove the eventhandlers on unmount
      window.removeEventListener('resize', resizeContainer, false);

      document.removeEventListener('fullscreenchange', resizeWithCheck, false);
      document.removeEventListener(
        'mozfullscreenchange',
        resizeWithCheck,
        false,
      );
      document.removeEventListener(
        'MSFullscreenChange',
        resizeWithCheck,
        false,
      );
      document.removeEventListener(
        'webkitfullscreenchange',
        resizeWithCheck,
        false,
      );
    };
  }, []);

  return (
    <>
      {/* {props.showDescriptionOnSessionBroadcast && (
        <Typography
          sx={{
            p: {
              md: 2,
            },
            pb: { md: 0 },
          }}
        >
          {props.sessionDescription}
        </Typography>
      )} */}

      <Box
        id="container"
        sx={{
          display: 'flex',
          flexBasis: '0',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          height: {
            // md: containerHeight
          },
          // maxHeight: 'calc(100vh - 40px)',
          overflowY: 'scroll',
          transform: 'translateZ(0)',
          // mt: document.fullscreenElement ? "50%" : "0",
          // transform: document.fullscreenElement ? "translateY(calc(-50% - 40px))" : 'unset',
          p: {
            md: 2,
          },
          // backgroundColor: "white"
        }}
      >
        <Box
          id="leftContainer"
          sx={{
            flex: {
              sm: '1 1 0',
              // md: fullScreen ? "3 1 0" : "2 1 0",
              md: document.fullscreenElement ? '3 1 0' : '2 1 0',
            },
          }}
        >
          <Box
            id="slot1"
            sx={
              {
                // height: slot1Height,
                // backgroundColor: "green",
              }
            }
          >
            <Frame
              sx={{
                overflow: 'hidden',
                zIndex: 0,
                position: 'relative',
                // minHeight: {
                //     xs: "280px",
                //     md: "unset"
                // }
              }}
            >
              {props[0]?.data.code && (
                <MediaEmbedPlayer code={props[0].data.code} />
              )}
            </Frame>
          </Box>

          <Box
            id="slot2Container"
            sx={{
              display: { xs: 'none', md: 'flex' },
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
              height: {
                // // xs: "100%",
                // md: slot2Height
              },
              mt: {
                xs: 2,
                md: 0,
              },
            }}
          >
            <Box
              sx={{
                flex: '1 1 0',
                // backgroundColor: 'yellow',
                pt: {
                  xs: 0,
                  md: 2,
                },
                pr: {
                  xs: 0,
                  // sm: 1.33
                },
              }}
            >
              <Frame
                sx={{
                  overflow: 'auto',
                  zIndex: 0,
                  position: 'relative',
                  height: {
                    xs: '300px',
                    md: '100%',
                  },
                  p: 1,
                  // minHeight: {
                  //     xs: "280px",
                  //     md: "unset"
                  // }
                }}
              >
                {/* {props.showAgendaOnSessionBroadcast
                  ? renderAgenda(props.id)
                  : props[2]?.data.code && (
                      <MediaEmbedNormal code={props[2].data.code} />
                    )} */}
                {renderAgenda(props.id)}
              </Frame>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            flex: {
              xs: '1 1 0',
              sm: '1 1 0',
            },
            // backgroundColor: "red",
            pl: {
              xs: 0,
              md: 2,
            },
            mt: {
              xs: 2,
              md: 0,
            },
            maxHeight: {
              xs: '40vh',
              md: 'unset',
            },
            minHeight: {
              xs: '280px',
              md: 'unset',
            },
          }}
        >
          <Frame
            sx={{
              overflow: 'hidden',
              zIndex: 0,
              position: 'relative',
            }}
          >
            {props[1]?.data.code && (
              <MediaEmbedSlido code={props[1].data.code} />
            )}
          </Frame>
        </Box>
      </Box>
    </>
  );
}
