export function formatTime(timeFormat: string, time: Date) {
  let formattedTime;
  time = new Date(time);
  const hours = time.getHours();
  const minutes = time.getMinutes();

  switch (timeFormat) {
    case 'longDot':
      formattedTime = `${hours}.${minutes < 10 ? '0' + minutes : minutes} Uhr`;
      break;
    case 'shortColon':
      formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;
      break;
    case 'longColon':
      formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes} Uhr`;
      break;
    default:
      formattedTime = `${hours}.${minutes < 10 ? '0' + minutes : minutes}`;
  }

  return formattedTime;
}

export function formatDate(dateFormat: string, date: Date) {
  console.log('dateFormat-1', dateFormat);

  let formatedDate;

  date = new Date(date);

  switch (dateFormat) {
    case 'mediumDot':
      formatedDate = date.toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      break;
    case 'longDot':
      formatedDate = date.toLocaleDateString('de-DE', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      });
      break;
    case 'shortSlash':
      formatedDate = date
        .toLocaleDateString('de-DE', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        })
        .replace(/\./g, '/');
      break;
    case 'mediumSlash':
      formatedDate = date
        .toLocaleDateString('de-DE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        .replace(/\./g, '/');
      break;
    default:
      formatedDate = date.toLocaleDateString('de-DE', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      });
  }

  console.log('dateFormat-2', formatedDate);

  return formatedDate;
}
