import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import { RegistrationFormTypes } from './registrationFormTypes';
import { Button, Stack, TextField, Typography, useTheme } from '@mui/material';
import { ErTextField } from 'eventrocket-commoncomponents';
import { ErCheckbox } from 'eventrocket-commoncomponents';
import { getErrorMessage } from '../../util/formHelpers';
import usePlayerStore from '../../store/playerStore';
import { useEffect } from 'react';

type RegistrationFormLayoutProps = {
  control: Control<RegistrationFormTypes>;
  errors?: FieldErrors;
  setActiveFormError?: (error: boolean) => void;
  trigger?: any;
  setValue: (name: keyof RegistrationFormTypes, file: any) => void;
  getValues?: (fieldName: string) => any;
  watch: UseFormWatch<RegistrationFormTypes>;
  handleSubmit: (
    onValid: (data: any) => void,
  ) => (event?: React.BaseSyntheticEvent) => void;
  handleFormSubmit: (data: RegistrationFormTypes) => void;
};

export default function RegistrationFormLayout(
  props: RegistrationFormLayoutProps,
) {
  // props
  const {
    control,
    handleSubmit,
    handleFormSubmit,
    errors,
    trigger,
    setValue,
    getValues,
    watch,
  } = props;

  // registration settings data
  const { registrationData, onlineStageData } = usePlayerStore();

  // watch certificate
  const needCertificate = watch('certificate');

  // theme
  const theme = useTheme();

  // stylesTextfield
  const stylesTextfield = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.playerBgColor.contrastText, // Farbe des Feldrahmens
        // opacity: 0.4,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main, // Farbe des Feldrahmens bei Hover
      },
      // '&.Mui-focused fieldset': {
      //   borderColor: theme.palette.playerBgColor.contrastText, // Farbe des Feldrahmens bei Fokus
      //   opacity: 1,
      // },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.playerBgColor.contrastText, // Farbe des Labels
    },
    '& .MuiInputLabel-shrink': {
      color: theme.palette.primary.main, // Farbe des Labels
    },
    // '& .MuiInputLabel-root.Mui-focused': {
    //   color: 'yourFocusLabelColor', // Farbe des Labels bei Fokus
    // },
    '& .MuiOutlinedInput-input': {
      color: theme.palette.playerBgColor.contrastText, // Farbe des Eingabetexts
    },
  };

  const stylesCheckbox = {
    // color: theme.palette.playerBgColor.contrastText,
    '& .MuiCheckbox-root': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.playerBgColor.contrastText, // Farbe des Kontrollkästchens
        // '& .Mui-checked .MuiSvgIcon-root': {
        //   color: theme.palette.primary.main, // Farbe des Kontrollkästchens
        // },
      },
      '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
          color: theme.palette.primary.main,
        },
      },
    },
  };

  ////
  // reset memberId if necessary
  useEffect(() => {
    if (registrationData!.fieldDefinition.certificate.required) {
      setValue('certificate', true);
    }

    if (!needCertificate) {
      setValue('memberId', '');
    }
  }, [needCertificate]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <Stack sx={{ color: theme.palette.playerBgColor.contrastText }}>
        {/* Form header */}
        <Typography variant="h5" sx={{ fontWeight: 'bold' }} mb={2}>
          Ihre Daten
        </Typography>
        {/* <TextField
          label="Test"
          // FormHelperTextProps={{ sx: { color: 'red' } }}
          InputLabelProps={{ sx: { color: 'red' } }}
          inputProps={{ sx: { color: 'red' } }}
          InputProps={{ sx: { color: 'red' } }}
          SelectProps={{ sx: { color: 'red' } }}
        /> */}
        <Stack direction="row" spacing={1}>
          <ErTextField
            sx={{ mb: 2, ...stylesTextfield }}
            required
            control={control}
            fieldLabel="Vorname"
            fieldName="firstname"
            size="small"
            fullWidth
            error={errors?.firstname && getErrorMessage(errors, 'firstname')}
          />

          <ErTextField
            sx={{ mb: 2, ...stylesTextfield }}
            required
            control={control}
            fieldLabel="Nachname"
            fieldName="lastname"
            size="small"
            fullWidth
            error={errors?.lastname && getErrorMessage(errors, 'lastname')}
          />
        </Stack>

        <ErTextField
          sx={{ mb: 2, ...stylesTextfield }}
          required
          control={control}
          fieldLabel="Email"
          fieldName="email"
          size="small"
          fullWidth
          error={errors?.email && getErrorMessage(errors, 'email')}
        />

        {onlineStageData!.accessCodeRequired && (
          <ErTextField
            sx={{ mb: 2, ...stylesTextfield }}
            required
            control={control}
            fieldLabel="Zugangscode"
            fieldName="accessCode"
            size="small"
            fullWidth
            error={errors?.accessCode && getErrorMessage(errors, 'accessCode')}
          />
        )}

        {registrationData!.fieldDefinition.phone?.active && (
          <ErTextField
            sx={{ mb: 2, ...stylesTextfield }}
            required={registrationData!.fieldDefinition.phone.required}
            control={control}
            fieldLabel="Telefon"
            fieldName="telephone"
            size="small"
            fullWidth
            error={errors?.telephone && getErrorMessage(errors, 'telephone')}
          />
        )}

        {registrationData!.fieldDefinition.organisation?.active && (
          <ErTextField
            sx={{ mb: 2, ...stylesTextfield }}
            required={registrationData!.fieldDefinition.organisation?.required}
            control={control}
            fieldLabel="Organisation"
            fieldName="organization"
            size="small"
            fullWidth
            error={
              errors?.organization && getErrorMessage(errors, 'organization')
            }
          />
        )}

        {registrationData!.fieldDefinition.address?.active && (
          <ErTextField
            sx={{ mb: 2, ...stylesTextfield }}
            required={registrationData!.fieldDefinition.address?.required}
            control={control}
            fieldLabel="Adresse"
            fieldName="address"
            size="small"
            fullWidth
            error={errors?.address && getErrorMessage(errors, 'address')}
          />
        )}

        {registrationData!.fieldDefinition.certificate?.active && (
          <>
            <ErCheckbox
              sx={{ mb: 2, ...stylesCheckbox }}
              required
              control={control}
              fieldLabel="Ich wünsche eine Teilnahmebestätigung"
              fieldName="certificate"
              size="small"
              disabled={registrationData!.fieldDefinition.certificate.required}
              error={
                errors?.certificate && getErrorMessage(errors, 'certificate')
              }
            />
            {registrationData!.fieldDefinition.memberId?.active &&
              needCertificate && (
                <ErTextField
                  sx={{ mb: 2, ...stylesTextfield }}
                  required={
                    registrationData!.fieldDefinition.memberId?.required
                  }
                  control={control}
                  fieldLabel="Mitgliedsnummer"
                  fieldName="memberId"
                  size="small"
                  fullWidth
                  error={
                    errors?.memberId && getErrorMessage(errors, 'memberId')
                  }
                />
              )}
          </>
        )}

        <ErCheckbox
          sx={{ mb: 2, ...stylesCheckbox }}
          required
          control={control}
          fieldLabel="Ich bin mit der Verarbeitung meiner Daten einverstanden."
          fieldName="privacyConfirmed"
          size="small"
          error={
            errors?.privacyConfirmed &&
            getErrorMessage(errors, 'privacyConfirmed')
          }
        />

        <ErCheckbox
          sx={{ mb: 2, ...stylesCheckbox }}
          required
          control={control}
          fieldLabel="Verwendung von Cookies akzeptieren"
          fieldName="cookiesAccepted"
          size="small"
          error={
            errors?.cookiesAccepted &&
            getErrorMessage(errors, 'cookiesAccepted')
          }
        />

        <Button
          type="submit"
          variant="contained"
          // sx={{ mt: '24px !important' }}
        >
          Absenden
        </Button>
      </Stack>
    </form>
  );
}
