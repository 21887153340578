import Parse from 'parse';
import {
  Outlet,
  redirect,
  useLocation,
  useNavigate,
  useNavigation,
} from 'react-router-dom';
import usePlayerStore from '../store/playerStore';
import { Box, Container, Theme, useMediaQuery, useTheme } from '@mui/material';
import { Suspense, useEffect, useState } from 'react';
import { useFeedback } from '../Components/Feedback/FeedbackContext';
import PlayerHeader from '../Components/PlayerHeader';
import PlayerFooter from '../Components/PlayerFooter';
import useInterval from '../hooks/useInterval';
import useViewControl from '../hooks/useViewControl';
import useLiveStore from '../store/liveStore';
import fetchSubscription from '../data/liveDataSubscription';

export default function MainLayout() {
  // state
  const [actualSubscription, setActualSubscription] =
    useState<Parse.LiveQuerySubscription | null>(null);

  // use the stores
  const {
    eventId,
    playerUi,
    registrationData,
    onlineStageData,
    playerUser,
    setPlayerUser,
    isPreview,
  } = usePlayerStore();

  const { liveData, setLiveData } = useLiveStore();

  // theme
  const theme = useTheme();

  // mediaQuery
  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  // feedback
  const { setFeedback } = useFeedback();

  // navigation
  const navigate = useNavigate();
  const location = useLocation();

  // view navigation management
  const { vcInit } = useViewControl();

  //// side effects
  // init live data if we do not have registration and no access code
  useEffect(() => {
    if (
      (!registrationData && !onlineStageData!.accessCodeRequired) ||
      isPreview
    ) {
      fetchSubscription()
        .then((res) => setActualSubscription(res))
        .catch((e) => {
          console.error(e);
          setFeedback({ type: 'error', errorCode: e });
        });
    }
    return () => {
      // unsubscribe from the livequery on unmount
      actualSubscription?.unsubscribe();
    };
  }, []);

  //// init live query if we have an access code
  useEffect(() => {
    if (playerUser?.accessToken || !onlineStageData!.accessCodeRequired) {
      fetchSubscription()
        .then((res) => setActualSubscription(res))
        .catch((e) => {
          console.error(e);
          setFeedback({
            type: 'error',
            errorCode: e,
          });
          setPlayerUser({ accessToken: undefined });
        });
    }

    return () => {
      // unsubscribe from the livequery on unmount
      actualSubscription?.unsubscribe();
    };
  }, [playerUser]);

  return vcInit ? (
    // Player Bg
    <>
      {/* <ReactQueryDevtools /> */}
      <Box
        id="bs_Player_MainContainer"
        sx={{
          // width: '100vw',
          // height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          bgcolor: theme.palette.playerBgColor.main,
        }}
      >
        <Box
          id="bs_Player_HeaderContainer"
          sx={{
            position: 'relative',
            width: '100%',
            left: 0,
            // backgroundColor: '#fff',
            bgcolor: theme.palette.playerBgColor.light,
          }}
        >
          {/* Header */}
          <PlayerHeader />
        </Box>

        {/* Content */}
        <Box
          id="bs_Player_ContentContainer"
          sx={{
            flex: 1,
            bgcolor: theme.palette.playerBgColor.main,
          }}
        >
          <Container>
            {/* Suspense needed for translation loading problem... */}
            <Suspense fallback={<></>}>
              {/* Route Outlet */}
              <Outlet />
            </Suspense>
          </Container>
        </Box>

        <Box id="bs_Player_FooterContainer">
          <Container>
            {/* Footer */}
            <PlayerFooter />
          </Container>
        </Box>
      </Box>
    </>
  ) : (
    <></>
  );
}
