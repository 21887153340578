import { Box, Modal, Paper, Typography, useTheme } from '@mui/material';
import usePlayerStore from '../store/playerStore';
import { useEffect, useState } from 'react';
import { bool, boolean } from 'yup';
import EventAccessForm from './EventAccessForm/EventAccessForm';
import rewriteHtml from '../util/htmlTextHelpers';
import useAgenda from '../hooks/useAgenda';
import Countdown from './Countdown/Countdown';

export default function PlayerContentAnnounce() {
  // state
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModalOpen = () => {
    setModalOpen((state: boolean) => !state);
  };

  // global store
  const {
    playerUi,
    registrationData,
    playerUser,
    onlineStageData,
    marketingData,
    isPreview,
  } = usePlayerStore();

  console.log('playerUi.eventStartDateTime', playerUi!.eventStartDateTime);

  const { showEventText, showAnnouncementText, showProgram, showCountDown } =
    marketingData!;

  // theme
  const theme = useTheme();

  //
  // get Agenda
  // const Agenda = useAgenda({ stage: 'marketing' });
  const { renderProgram, renderAgenda } = useAgenda({ stage: 'marketing' });

  //// side effects
  useEffect(() => {
    if (
      !isPreview &&
      !registrationData &&
      (!playerUser?.cookiesAccepted || !playerUser?.accessToken)
    ) {
      setModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (
      !isPreview &&
      !registrationData &&
      (!playerUser?.cookiesAccepted ||
        (onlineStageData!.accessCodeRequired && !playerUser?.accessToken))
    ) {
      setModalOpen(true);
    }
  }, [playerUser]);

  return (
    <>
      <Box
        width="100%"
        pt={1}
        sx={{ color: theme.palette.playerBgColor.contrastText }}
      >
        {/* Eventtext */}
        {showEventText && (
          <Box sx={{ mb: 0 }}>{rewriteHtml(playerUi!.descriptionHtml)}</Box>
        )}

        {/* Announcementtext */}
        {showAnnouncementText && (
          <Box sx={{ mb: 0 }}>{rewriteHtml(playerUi!.announcementHtml)}</Box>
        )}

        {showCountDown && (
          <Countdown targetDate={playerUi!.eventStartDateTime} />
        )}

        {/* Program / agenda */}
        {showProgram && (
          <Box sx={{ mb: 0 }}>
            {playerUi!.multiSession ? renderProgram() : renderAgenda()}
          </Box>
        )}
      </Box>

      <Modal open={modalOpen}>
        <Paper
          sx={{
            maxWidth: '480px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 2,
          }}
        >
          <EventAccessForm callback={toggleModalOpen} />
        </Paper>
      </Modal>
    </>
  );
}
