import { Box, Typography, useTheme } from '@mui/material';
import usePlayerStore from '../store/playerStore';
import rewriteHtml from '../util/htmlTextHelpers';
import ConfirmationForm from './ConfirmationForm/ConfirmationForm';
import useAgenda from '../hooks/useAgenda';
import Countdown from './Countdown/Countdown';

export default function PlayerContentConfirmation() {
  // global store
  const { playerUi, registrationData } = usePlayerStore();

  console.log('##!##-registrationData', registrationData);

  // theme
  const theme = useTheme();

  // agenda
  const { renderProgram, renderAgenda } = useAgenda({ stage: 'marketing' });

  return (
    <Box
      width="100%"
      pt={1}
      sx={{ color: theme.palette.playerBgColor.contrastText }}
    >
      {registrationData!.showEventTextConfirmation &&
        rewriteHtml(playerUi!.descriptionHtml)}

      {registrationData!.showConfirmationTextConfirmation &&
        rewriteHtml(registrationData!.confirmationText)}

      {registrationData!.showCountDownConfirmation && (
        <Countdown targetDate={playerUi!.eventStartDateTime} />
      )}

      {/* <ConfirmationForm /> */}
      <ConfirmationForm />

      {registrationData!.showAgendaConfirmation && (
        <Box sx={{ mb: 0 }}>
          {playerUi!.multiSession ? renderProgram() : renderAgenda()}
        </Box>
      )}
    </Box>
  );
}
