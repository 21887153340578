import * as yup from 'yup';
import i18n from 'i18next';
import { ConfirmationFormTypes } from './confirmationFormTypes';
import usePlayerStore from '../../store/playerStore';

export const confirmationValSchema = yup.object({
  firstname: yup.string().required('Bitte den Vornamen angeben'),
  lastname: yup.string().required('Bitte den Nachnamen angeben'),
  // email: yup
  //   .string()
  //   .email()
  //   .required('benötigt, um Ihnen Updates zum Event zu senden'),
  privacyConfirmed: yup
    .boolean()
    .required()
    .oneOf([true], 'erforderlich, um am Online am Event teilzunehmen'),
  cookiesAccepted: yup.boolean().required().oneOf([true]),
  telephone: yup.string().when('fieldDefinition.phone.required', {
    is: true,
    then: () =>
      yup.string().required('benötigt, um bei Bedarf Kontakt aufzunehmen'),
  }),
  organization: yup.string().when('fieldDefinition.organisation.required', {
    is: true,
    then: () =>
      yup.string().required('Bitte teilen Sie uns Ihre Organisation mit'),
  }),
  address: yup.string().when('fieldDefinition.address.required', {
    is: true,
    then: () =>
      yup
        .string()
        .required('benötigt, um Ihnen Infos zum Event zukommen zu lassen'),
  }),
  certificate: yup.boolean(),
  memberId: yup.string().when('fieldDefinition.memberId.required', {
    is: true,
    then: () =>
      yup.string().required('für die Teilnahmebestätigung erforderlich'),
  }),
  // accessCode: yup.string().when('accessCodeRequired', {
  //   is: true,
  //   then: () => yup.string().required('Zugangscode ist falsch oder fehlt'),
  // }),
  accessToken: yup.string(),
});

export const confirmationDefaults: ConfirmationFormTypes = {
  firstname: '',
  lastname: '',
  // email: '',
  privacyConfirmed: false,
  telephone: '',
  organization: '',
  address: '',
  certificate: false,
  memberId: '',
  // cookiesAccepted: false,
  cookiesAccepted: false,
  // accessCode: '',
  accessToken: undefined,
};
