import Parse from 'parse';
import useLiveStore from '../store/liveStore';
import usePlayerStore from '../store/playerStore';

export default async function fetchSubscription() {
  // eventId
  const eventId = usePlayerStore.getState().eventId;
  const onlineStageData = usePlayerStore.getState().onlineStageData;
  const playerUser = usePlayerStore.getState().playerUser;
  const isPreview = usePlayerStore.getState().isPreview;
  const pSessionToken = usePlayerStore.getState().sessionToken;

  if (isPreview && pSessionToken) {
    const user = await Parse.User.become(pSessionToken);
  }

  // build query
  const liveQuery = new Parse.Query('PlayerLive');
  liveQuery.equalTo('event', {
    __type: 'Pointer',
    className: 'Event',
    objectId: eventId!.eventId,
  });

  if (
    onlineStageData?.accessCodeRequired &&
    playerUser?.accessToken &&
    !isPreview
  ) {
    // Parse.CoreManager.set('REQUEST_HEADERS', {
    //   'X-bsPlayer-Token': playerUser.accessToken,
    // });

    liveQuery.equalTo('token', playerUser.accessToken);
  }

  // initial query call
  const queryResult = await liveQuery
    .first()
    .then((res) => {
      if (res) {
        console.log('#LiveData', res);

        // setLiveData(res.toJSON());
        useLiveStore.setState({
          liveData: res.toJSON(),
          // liveDataSession: res.toJSON().sceneData.sessions,
        });
        useLiveStore.getState().setLiveData(res.toJSON());
        const sessions = res.toJSON().sceneData.sessions;
        useLiveStore.getState().setSessions(sessions);
      }
    })
    .catch((e) => {
      console.error(e);
      throw e;
    });

  //   // return error
  //   if (queryResult) {
  //     return queryResult;
  //   }

  // subscribe to the livequery
  const res = await liveQuery.subscribe();

  const handleLiveQueryEvent = (object: any) => {
    useLiveStore.getState().setLiveData(object.toJSON());
    const sessions = object.toJSON().sceneData.sessions;
    useLiveStore.getState().updateSessions(sessions);
  };

  res.on('create', handleLiveQueryEvent);
  res.on('update', handleLiveQueryEvent);
  res.on('enter', handleLiveQueryEvent);

  // // set the event handlers which will trigger the update of global store
  // res.on('create', (object) => {
  //   console.log('object created', object);
  //   // setLiveData(object.toJSON());
  //   // useLiveStore.setState({ liveData: object.toJSON() });
  //   useLiveStore.getState().setLiveData(object.toJSON());
  // });

  // res.on('update', (object) => {
  //   console.log('object updated', object);
  //   // setLiveData(object.toJSON());
  //   // useLiveStore.setState({ liveData: object.toJSON() });
  //   useLiveStore.getState().setLiveData(object.toJSON());
  // });

  // res.on('enter', (object) => {
  //   console.log('object updated', object);
  //   // setLiveData(object.toJSON());
  //   // useLiveStore.setState({ liveData: object.toJSON() });
  //   useLiveStore.getState().setLiveData(object.toJSON());
  // });

  // //   // save the subscription to state
  // //   setActualSubscription(res);
  return res;
}
