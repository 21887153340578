import { QueryStatus } from '@tanstack/react-query';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

type State = {
  [key: string]: any;
};

type EventId = { eventId: string; masterEventId: string };
type ConnectionData = {
  appId: string;
  javascriptKey: string;
  serverUrl: string;
};
type PlayerUi = {
  agendaHeadline: string;
  announcementHtml: string;
  bgColor: string;
  contactText: string;
  cookiesText: string;
  dateFormat: string;
  dateInTitle: boolean;
  description: string;
  descriptionHtml: string;
  displayContactText: boolean;
  displayCookiesText: boolean;
  displayImprintText: boolean;
  displayLegalText: boolean;
  dividerActors: string;
  dividerTitle: string;
  eventDuration: number;
  eventEnded: boolean;
  eventStartDateTime: Date;
  imprintText: string;
  legalText: string;
  locationInTitle: boolean;
  multiSession: boolean;
  primaryColor: string;
  programHeadline: string;
  secondaryColor: string;
  slotTitleInSecondaryColor: boolean;
  textSize: 'small' | 'medium' | 'large';
  timeFormat: string;
  title: string;
  bannerImg: string | undefined;
  logo: string | undefined;
  onlineStagePlayState: { day: Date; state: string }[] | undefined;
};

type RegistrationData = {
  confirmEmail: boolean;
  confirmationText: string;
  createdAt: string;
  embed: boolean;
  fieldDefinition: any;
  objectId: string;
  registrationStarts: Date;
  registrationText: string;
  registrationUrl: string;
  showAgenda: boolean;
  showAgendaConfirmation: boolean;
  showAgendaWelcome: boolean;
  showConfirmationTextConfirmation: boolean;
  showCountDown: boolean;
  showCountDownConfirmation: boolean;
  showCountDownWelcome: boolean;
  showEventText: boolean;
  showEventTextConfirmation: boolean;
  showEventTextWelcome: boolean;
  showRegistrationText: boolean;
  showWelcomeTextWelcome: boolean;
  updatedAt: string;
  welcomeText: string;
};

type OnlineStageData = {
  accessCodeRequired: boolean;
  autoStartEvent: boolean;
  createdAt: string;
  embed: boolean;
  eventUrl: string;
  fallbackScene: string;
  farewellText: string;
  objectId: string;
  openingTimes: any[];
  playerText: string;
  showAgenda: boolean;
  showAgendaFarewell: boolean;
  showCountDown: boolean;
  showCountDownFarewell: boolean;
  showEventText: boolean;
  showEventTextFarewell: boolean;
  showFarewellText: boolean;
  showLobbyText: boolean;
  starttimeLobby: Date;
  updatedAt: string;
};

type Agenda = {
  live: { event?: any; sessions: any[] };
  marketing: { event?: any; sessions: any[] };
  preview: { event?: any; sessions: any[] };
};

type MarketingData = {
  announcementHtml: string;
  createdAt: string;
  objectId: string;
  showAnnouncementText: boolean;
  showCountDown: boolean;
  showEventText: boolean;
  showProgram: boolean;
  updatedAt: string;
};

type PlayerUser = {
  address: string;
  allowCardDownload?: boolean;
  certificate: boolean;
  certificateRequired?: boolean;
  cookiesAccepted: boolean;
  createdAt?: Date;
  currentSessionId: string;
  displayUserInPublicList?: boolean;
  email: string;
  firstname: string;
  invitationSent?: boolean;
  lastname: string;
  memberId?: string;
  organization?: string;
  personalAccessCode?: string;
  preselectedKeynotes?: any;
  privacyConfirmed: boolean;
  telephone?: string;
  trackingData?: any[];
  updatedAt?: Date;
  userMailConfirmed?: boolean;
  userParticipationConfirmed?: boolean;
  uct?: string;
  accessToken?: string;
};

type PlayerStore = {
  eventId: EventId | null;
  setEventId: (data: EventId) => void;
  connectionData: ConnectionData | null;
  setConnectionData: (data: ConnectionData) => void;
  isPreview: boolean;
  setIsPreview: (data: boolean) => void;
  previewAgendaSessionId: string | undefined;
  setPreviewAgendaSessionId: (data: string) => void;
  sessionToken: string | null;
  setSessionToken: (data: string) => void;
  userToken: string | null;
  setUserToken: (data: string) => void;
  selectedSession: string | null;
  setSelectedSession: (data: string) => void;
  previewSession: string | null;
  setPreviewSession: (data: string) => void;
  playerUi: PlayerUi | null;
  setPlayerUi: (data: PlayerUi) => void;
  registrationData: RegistrationData | null;
  setRegistrationData: (data: RegistrationData) => void;
  onlineStageData: OnlineStageData | null;
  setOnlineStageData: (data: OnlineStageData) => void;
  agenda: any;
  setAgenda: (data: any) => void;
  marketingData: MarketingData | null;
  setMarketingData: (data: MarketingData) => void;
  playerUiStatus: QueryStatus;
  setPlayerUiStatus: (data: QueryStatus) => void;
  playerUser: PlayerUser | null;
  setPlayerUser: (data: any) => void;
  // [key: string]: any;
};

// init empty store
const usePlayerStore = create<PlayerStore>((set) => ({
  eventId: null,
  setEventId: (data: any) => set({ eventId: data }),

  connectionData: null,
  setConnectionData: (data: any) => set({ connectionData: data }),

  isPreview: false,
  setIsPreview: (data: any) => set({ isPreview: data }),
  previewAgendaSessionId: undefined,
  setPreviewAgendaSessionId: (data: any) =>
    set({ previewAgendaSessionId: data }),

  sessionToken: null,
  setSessionToken: (data: any) => set({ sessionToken: data }),

  userToken: null,
  setUserToken: (data: any) => set({ userToken: data }),

  selectedSession: null,
  setSelectedSession: (data: any) => set({ selectedSession: data }),

  previewSession: null,
  setPreviewSession: (data: any) => set({ previewSession: data }),

  playerUi: null,
  setPlayerUi: (data: any) => set({ playerUi: data }),
  registrationData: null,
  setRegistrationData: (data: any) => set({ registrationData: data }),
  onlineStageData: null,
  setOnlineStageData: (data: any) => set({ onlineStageData: data }),
  agenda: null,
  setAgenda: (data: any) => set({ agenda: data }),
  marketingData: null,
  setMarketingData: (data: any) => set({ marketingData: data }),
  playerUiStatus: 'pending',
  setPlayerUiStatus: (status: QueryStatus) => set({ playerUiStatus: status }),

  playerUser: null,
  setPlayerUser: (data: any) =>
    set((state) => ({ playerUser: { ...state.playerUser, ...data } })),
}));

export default usePlayerStore;
