import { Theme } from '@mui/material';

export default function MuiTypography(theme: Theme) {
  return {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'dialogHeader' },
          style: {
            textTransform: 'none',
            fontSize: '20px !important',
            fontWeight: '500',
            padding: '16px 20px 0px 20px',
          },
        },
        {
          props: { variant: 'logo' },
          style: {
            fontFamily: 'Orbitron',
            fontSize: '0.75rem',
            fontWeight: 500,
            letterSpacing: '0.2em',
            textTransform: 'uppercase',
            [theme.breakpoints.up('sm')]: {
              fontSize: '1.25rem',
            },
          },
        },
        {
          props: { variant: 'subtitle3' },
          style: {
            fontWeight: 300,
            fontSize: '0.75rem',
            lineHeight: 1,
            letterSpacing: '0.0016em',
          },
        },
        {
          props: { variant: 'InfoTextBold' },
          style: {
            fontWeight: 500,
            fontSize: '0.875rem',
            lineHeight: 'normal',
          },
        },
        {
          props: { variant: 'InfoText' },
          style: {
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: 'normal',
          },
        },
      ],
    },
  };
}
