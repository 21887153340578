import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import { AccessCodeFormTypes } from './accessCodeFormTypes';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { ErTextField } from 'eventrocket-commoncomponents';
import { getErrorMessage } from '../../util/formHelpers';

type RegistrationFormLayoutProps = {
  control: Control<AccessCodeFormTypes>;
  errors?: FieldErrors;
  setActiveFormError?: (error: boolean) => void;
  trigger?: any;
  setValue?: (name: keyof AccessCodeFormTypes, file: any) => void;
  getValues?: (fieldName: string) => any;
  watch?: UseFormWatch<AccessCodeFormTypes>;
  handleSubmit: (
    onValid: (data: any) => void,
  ) => (event?: React.BaseSyntheticEvent) => void;
  handleFormSubmit: (data: AccessCodeFormTypes) => void;
};

export default function AccessCodeFormLayout(
  props: RegistrationFormLayoutProps,
) {
  // props
  const {
    control,
    handleSubmit,
    handleFormSubmit,
    errors,
    trigger,
    setValue,
    getValues,
    watch,
  } = props;

  // theme
  const theme = useTheme();

  // stylesTextfield
  const stylesTextfield = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.playerBgColor.contrastText, // Farbe des Feldrahmens
        // opacity: 0.4,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main, // Farbe des Feldrahmens bei Hover
      },
      // '&.Mui-focused fieldset': {
      //   borderColor: theme.palette.playerBgColor.contrastText, // Farbe des Feldrahmens bei Fokus
      //   opacity: 1,
      // },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.playerBgColor.contrastText, // Farbe des Labels
    },
    '& .MuiInputLabel-shrink': {
      color: theme.palette.primary.main, // Farbe des Labels
    },
    // '& .MuiInputLabel-root.Mui-focused': {
    //   color: 'yourFocusLabelColor', // Farbe des Labels bei Fokus
    // },
    '& .MuiOutlinedInput-input': {
      color: theme.palette.playerBgColor.contrastText, // Farbe des Eingabetexts
    },
  };

  const stylesCheckbox = {
    // color: theme.palette.playerBgColor.contrastText,
    '& .MuiCheckbox-root': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.playerBgColor.contrastText, // Farbe des Kontrollkästchens
        // '& .Mui-checked .MuiSvgIcon-root': {
        //   color: theme.palette.primary.main, // Farbe des Kontrollkästchens
        // },
      },
      '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
          color: theme.palette.primary.main,
        },
      },
    },
  };

  return (
    <>
      <Stack sx={{ color: theme.palette.playerBgColor.contrastText }}>
        <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          {/* Form header */}
          <Typography variant="h5" sx={{ fontWeight: 'bold' }} mb={2}>
            Ihre persönlicher Zugangscode
          </Typography>

          <ErTextField
            sx={{ mb: 2, ...stylesTextfield }}
            required
            control={control}
            fieldLabel="Zugangscode"
            fieldName="personalAccessCode"
            size="small"
            fullWidth
            error={errors?.firstname && getErrorMessage(errors, 'firstname')}
          />

          <Button
            type="submit"
            variant="contained"
            fullWidth
            // sx={{ mt: '24px !important' }}
          >
            Absenden
          </Button>
        </form>
      </Stack>
    </>
  );
}
