import React from 'react';
import { Typography } from '@mui/material';
import useCountdown from './useCountdown';

interface CountdownProps {
  targetDate: Date;
}

const Countdown: React.FC<CountdownProps> = ({ targetDate }) => {
  const { days, hours, minutes, seconds } = useCountdown(targetDate);

  const renderTimePart = (value: number, unit: string, show: boolean) => {
    // return show && value > 0 ? `${value} ${unit}${value > 1 ? 'n' : ''} ` : '';
    return value > 0 || show ? `${value} ${unit}${value > 1 ? 'n' : ''} ` : '';
  };

  const renderTimePartDay = (value: number, unit: string) => {
    return value > 0 ? `${value} ${unit}${value > 1 ? 'e' : ''} ` : '';
  };

  return (
    <Typography
      variant="body1"
      // align="center"
      fontWeight="fontWeightBold"
      pt="1rem"
    >
      {days > 0 || hours > 0 || minutes > 0 || seconds > 0 ? 'Noch' : ''}{' '}
      {renderTimePartDay(days, 'Tag')}
      {renderTimePart(hours, 'Stunde', days > 0)}
      {renderTimePart(minutes, 'Minute', days > 0 || hours > 0)}
      {renderTimePart(
        seconds,
        'Sekunde',
        days > 0 || hours > 0 || minutes > 0,
      )}{' '}
      {days > 0 || hours > 0 || minutes > 0 || seconds > 0
        ? 'bis zum Einlass…'
        : ''}
    </Typography>
  );
};

export default Countdown;
