import React, { useEffect, useRef } from 'react';

import 'videojs-youtube';
// import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm';
import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.cjs';

import videojs from 'video.js';
import 'video.js/dist/video-js.css';

interface VideoJSOptions {
  autoplay?: boolean;
  sources?: Array<{ type: string; src: string }>;
  // Füge weitere Optionen hinzu, die von video.js akzeptiert werden
}

interface VideoJSProps {
  options: VideoJSOptions;
  onReady: (player: any) => void;
}

export const VideoJS: React.FC<VideoJSProps> = ({ options, onReady }) => {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<any | null>(null);

  console.log('options', options);

  useEffect(() => {
    if (!playerRef.current && videoRef.current) {
      const videoElement = document.createElement('video-js');
      videoElement.className = 'video-js vjs-big-play-centered';

      videoRef.current.appendChild(videoElement);

      const player = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        onReady(player);
      });
      playerRef.current = player;

      console.log('player', player);
    } else if (playerRef.current) {
      // const player = playerRef.current;
      // if (options.autoplay !== undefined) player.autoplay(options.autoplay);
      // if (options.sources) player.src(options.sources);
    }
  }, [options, onReady]);

  useEffect(() => {
    return () => {
      if (playerRef.current && !playerRef.current.isDisposed()) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <>
      <style>
        {`
          .video-js {width: 100%; height: 100%;}
        `}
      </style>
      <div data-vjs-player>
        <div ref={videoRef} className="video-js vjs-big-play-centered" />
      </div>
    </>
  );
};

export default VideoJS;

// import { useEffect, useRef } from 'react';
// import videojs from 'video.js';
// import 'video.js/dist/video-js.css';
// import 'videojs-youtube';
// import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm';

// type VideoJSProps = { options: any; onReady: any };

// export const VideoJS = (props: VideoJSProps) => {
//   const videoRef = useRef<HTMLVideoElement | null>(null);
//   const playerRef = useRef<any>(null);
//   const { options, onReady } = props;

//   console.log('videoProps', props);
//   console.log('videoRef', videoRef.current);
//   console.log('playerRef', playerRef.current);

//   useEffect(() => {
//     // make sure Video.js player is only initialized once
//     if (!playerRef.current && videoRef.current) {
//       // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
//       const videoElement = document.createElement('video-js');

//       videoElement.classList.add('vjs-big-play-centered');
//       videoRef.current.appendChild(videoElement);

//       const player = (playerRef.current = videojs(videoElement, options, () => {
//         videojs.log('player is ready');
//         onReady && onReady(player);
//       }));
//     } else {
//       // you can update player here [update player through props]
//       const player = playerRef.current;
//       player.autoplay(options.autoplay);
//       player.src(options.sources);
//     }
//   }, [options, videoRef]);

//   // Dispose the Video.js player when the functional component unmounts
//   useEffect(() => {
//     const player = playerRef.current;

//     return () => {
//       // if (!player || player.isDisposed()) return;
//       // player.dispose();
//       // playerRef.current = null;
//       if (player && !player.isDisposed()) {
//         player.dispose();
//         playerRef.current = null;
//       }
//     };
//   }, [playerRef.current]);

//   return (
//     <div data-vjs-player>
//       <video ref={videoRef} className="video-js vjs-big-play-centered" />
//     </div>
//   );

//   // return <>TEST-VideoJS</>;
// };

// export default VideoJS;

// import { useEffect, useRef } from 'react';
// import videojs from 'video.js';
// import 'video.js/dist/video-js.css';
// import 'videojs-youtube';
// import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm';

// type VideoJSProps = { options: any; onReady: any };

// export const VideoJS = (props: VideoJSProps) => {
//   const videoRef = useRef(null);
//   const playerRef = useRef<any>(null);
//   const { options, onReady } = props;

//   console.log('videoProps', props);
//   console.log('videoRef', videoRef.current);
//   console.log('playerRef', playerRef.current);

//   useEffect(() => {
//     // make sure Video.js player is only initialized once
//     if (!playerRef.current) {
//       const videoElement = videoRef.current;
//       if (!videoElement) return;

//       const player = (playerRef.current = videojs(videoElement, options, () => {
//         onReady && onReady(player);
//       }));
//     } else {
//       // you can update player here [update player through props]
//       // const player = playerRef.current;
//       // player.autoplay(options.autoplay);
//       // player.src(options.sources);
//     }
//   }, [options, videoRef]);

//   // Dispose the Video.js player when the functional component unmounts
//   useEffect(() => {
//     const player = playerRef.current;

//     return () => {
//       if (player) {
//         player.dispose();
//         playerRef.current = null;
//       }
//     };
//   }, [playerRef]);

//   return (
//     <div data-vjs-player>
//       <video ref={videoRef} className="video-js vjs-big-play-centered" />
//     </div>
//   );

//   // return <>TEST-VideoJS</>;
// };

// export default VideoJS;
