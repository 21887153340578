import { create } from 'zustand';

type Session = {
  id: string;
  sceneData: {
    layout: number;
    widgets: any;
    general: any;
  };
  status: string;
};

type LiveData = {
  closed?: boolean;
  createdAt: string;
  event?: Parse.Pointer;
  eventPlayState?: any;
  objectId: string;
  sceneData?: any;
  updatedAt: string;
};

type State = {
  liveData: LiveData | null;
  // liveDataSession: any;
  setLiveData: (data: any) => void;
  sessions: { [id: string]: Session };
  setSessions: (sessions: Session[]) => void;
  updateSessions: (sessions: Session[]) => void;
};

// helper to compare deeply nested objects
const deepEqual = (obj1: any, obj2: any) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

// init empty store
const useLiveStore = create<State>((set) => ({
  liveData: null,
  // liveDataSession: null,
  setLiveData: (data: any) => {
    set({ liveData: data });
    // set({ liveDataSession: data.sceneData.sessions });
  },
  sessions: {},
  setSessions: (sessions) => {
    const sessionsObj = sessions.reduce(
      (acc, session) => {
        acc[session.id] = session;
        return acc;
      },
      {} as { [id: string]: Session },
    );
    set({ sessions: sessionsObj });
  },
  updateSessions: (newSessions) => {
    set((state) => {
      const updatedSessions = { ...state.sessions };
      newSessions.forEach((newSession) => {
        const currentSession = state.sessions[newSession.id];
        if (!currentSession || !deepEqual(currentSession, newSession)) {
          updatedSessions[newSession.id] = newSession;
        }
      });
      return { sessions: updatedSessions };
    });
  },
}));

export default useLiveStore;
