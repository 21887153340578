export default function MuiIconButton() {
  return {
    MuiIconButton: {
      variants: [
        {
          props: { size: 'dialogClose' },
          style: {
            textTransform: 'none',
            // height: 48,
            fontSize: '24px',
            padding: '8px',
          },
        },
      ],
    },
  };
}
