import { Theme } from '@mui/material';

export default function MuiModal(theme: Theme) {
  return {
    MuiModal: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            border: `1px solid ${theme.palette.overlayBorder.main}`,
          },
        },
      },
    },
  };
}
