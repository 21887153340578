import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import usePlayerStore from '../store/playerStore';
import { useLocation } from 'react-router-dom';
import { formatDate, formatTime } from '../util/dateTimeFormats';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import useLiveStore from '../store/liveStore';

export default function PlayerHeader() {
  // theme
  const theme = useTheme();

  // mediaQuery
  // mobile
  const mediaQuery = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );
  // tablet
  const mediaQueryTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );

  // url params
  const location = useLocation();

  // eventUiData
  const {
    playerUi,
    setSelectedSession,
    selectedSession,
    isPreview,
    previewSession,
  } = usePlayerStore();

  const { dateFormat, timeFormat, eventStartDateTime, multiSession } =
    playerUi!;

  // get live data of the active session
  // const { liveDataSession } = useLiveStore();

  let curLiveData;

  if (playerUi!.multiSession) {
    // curLiveData = liveDataSession?.find(
    //   (ses: any) => ses.id === selectedSession,
    // );
    curLiveData = useLiveStore((state) => {
      if (isPreview) {
        return state.sessions[previewSession!];
      } else {
        return state.sessions[selectedSession!];
      }
    });
  } else {
    // curLiveData = liveDataSession[0];
    curLiveData = useLiveStore((state) => Object.values(state.sessions)[0]);
  }

  console.log('##!##!#-curLiveDataHEADER', curLiveData);

  return (
    <>
      {playerUi!.bannerImg && playerUi!.bannerImg !== '' && (
        <Box
          id="bs_Player_Header_BgImageBlur"
          sx={{
            display: mediaQuery ? 'none' : mediaQueryTablet ? 'none' : 'block',
            mb: 0,
            width: '100vw',
            height: {
              xs: '220px',
              md: '300px',
            },
            left: 0,
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <img
            src={playerUi!.bannerImg}
            alt="Event thumbnail"
            style={{
              width: '140%',
              height: '140%',
              objectFit: 'cover',
              filter: 'blur(20px)',
              margin: '-40px -80px -80px -40px',
            }}
          />
        </Box>
      )}

      <Container
        sx={{
          px: mediaQuery ? 0 : mediaQueryTablet ? '0px !important' : '16px',
        }}
      >
        {/* Logo display */}
        {playerUi!.bannerImg && playerUi!.bannerImg !== '' && (
          <Box
            id="bs_Player_Header_BgImage"
            sx={{
              mt: {
                xs: '0px',
                // xs: '-180px',
                // sm: '-260px',
                md: '-260px',
              },
              height: {
                // xs: '180px',
                xs: 'unset',
                // sm: '260px',
                md: '260px',
              },
              width: '100%',
              position: 'relative',
            }}
          >
            <img
              src={playerUi!.bannerImg}
              alt="Event thumbnail"
              style={
                mediaQuery
                  ? { width: '100%' }
                  : mediaQueryTablet
                    ? { width: '100%' }
                    : {
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }
              }
            />
          </Box>
        )}

        {playerUi!.bannerImg &&
          playerUi!.bannerImg !== '' &&
          playerUi!.logo &&
          playerUi!.logo !== '' && (
            <Stack direction="row">
              <img
                id="bs_Player_Header_Logo"
                alt=""
                src={playerUi!.logo}
                width="auto"
                height={
                  mediaQuery ? '50px' : mediaQueryTablet ? '70px' : '90px'
                }
                style={{
                  marginLeft: mediaQuery
                    ? '40px'
                    : mediaQueryTablet
                      ? '40px'
                      : '20px',
                  marginTop: mediaQuery
                    ? '-44px'
                    : mediaQueryTablet
                      ? '-58px'
                      : '-78px',
                  zIndex: 1,
                  padding: mediaQuery ? '2px' : '4px',
                  backgroundColor: '#fff',
                  border: '1px solid #',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 6px 0px',
                }}
              />
            </Stack>
          )}

        {mediaQuery ? (
          <>
            <Stack direction="row" spacing={0}>
              {playerUi!.multiSession &&
                (location.pathname === '/live' ||
                  location.pathname === '/sessionWelcome') && (
                  <IconButton
                    sx={{ color: theme.palette.playerBgColor.contrastText }}
                    onClick={() => setSelectedSession('')}
                  >
                    <ArrowBackIosNewOutlinedIcon />
                  </IconButton>
                )}
              <Box>
                <Stack>
                  <Typography
                    id="bs_Player_Header_Headline"
                    variant="h5"
                    component="h2"
                    color={theme.palette.playerBgColor.contrastText}
                    sx={{
                      mt:
                        playerUi!.bannerImg &&
                        playerUi!.bannerImg !== '' &&
                        playerUi!.logo &&
                        playerUi!.logo !== ''
                          ? 2
                          : 0,
                      pt:
                        playerUi!.bannerImg &&
                        playerUi!.bannerImg !== '' &&
                        playerUi!.logo &&
                        playerUi!.logo !== ''
                          ? 0
                          : 2,
                      pb:
                        // playerUi!.dateInTitle ||
                        location.pathname === '/live' ||
                        location.pathname === '/sessionWelcome'
                          ? playerUi!.multiSession
                            ? 0
                            : 2
                          : playerUi!.dateInTitle &&
                              location.pathname !== '/confirm' &&
                              location.pathname !== '/closed' &&
                              location.pathname !== '/lobby'
                            ? 0
                            : 2,

                      pr: '16px',
                      pl:
                        location.pathname === '/live' ||
                        location.pathname === '/sessionWelcome'
                          ? playerUi!.multiSession
                            ? 0
                            : 2
                          : '16px',
                      // pl: 2,
                      fontWeight: 'bold',
                    }}
                  >
                    {playerUi!.title}
                  </Typography>

                  {(location.pathname === '/live' ||
                    location.pathname === '/sessionWelcome') &&
                    playerUi!.multiSession && (
                      <Typography
                        id="bs_Player_Header_Subline"
                        variant="h6"
                        component="h3"
                        color={theme.palette.playerBgColor.contrastText}
                        pb={2}
                      >
                        {curLiveData?.sceneData?.general?.sessionTitle}
                      </Typography>
                    )}
                </Stack>

                {(location.pathname === '/' ||
                  location.pathname === '/registration' ||
                  location.pathname === '/confirmation' ||
                  location.pathname === '/welcome') &&
                  playerUi!.dateInTitle && (
                    <Typography
                      id="bs_Player_Header_Headline"
                      variant="h5"
                      component="h2"
                      color={theme.palette.playerBgColor.contrastText}
                      sx={{
                        mt: 0,
                        // pt:
                        //   playerUi!.bannerImg &&
                        //   playerUi!.bannerImg !== '' &&
                        //   playerUi!.logo &&
                        //   playerUi!.logo !== ''
                        //     ? 2
                        //     : 0,
                        pt: 0,
                        pb: 2,
                        px: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      {formatDate(dateFormat, eventStartDateTime) +
                        ' um ' +
                        formatTime(timeFormat, eventStartDateTime)}
                    </Typography>
                  )}
              </Box>
            </Stack>
          </>
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography
                  id="bs_Player_Header_Headline"
                  variant="h3"
                  component="h2"
                  color={theme.palette.playerBgColor.contrastText}
                  sx={{
                    mt:
                      playerUi!.bannerImg &&
                      playerUi!.bannerImg !== '' &&
                      playerUi!.logo &&
                      playerUi!.logo !== ''
                        ? 2
                        : 0,
                    pt:
                      playerUi!.bannerImg &&
                      playerUi!.bannerImg !== '' &&
                      playerUi!.logo &&
                      playerUi!.logo !== ''
                        ? 0
                        : 2,
                    pb:
                      location.pathname === '/live' ||
                      location.pathname === '/sessionWelcome'
                        ? playerUi!.multiSession
                          ? 0
                          : 2
                        : 2,
                    fontWeight: 'bold',
                    px: mediaQueryTablet ? '16px' : 'unset',
                  }}
                >
                  {location.pathname === '/' ||
                  location.pathname === '/registration' ||
                  location.pathname === '/confirmation' ||
                  location.pathname === '/welcome'
                    ? playerUi!.title +
                      '' +
                      (playerUi!.dateInTitle
                        ? playerUi!.dividerTitle +
                          '' +
                          formatDate(dateFormat, eventStartDateTime) +
                          ' um ' +
                          formatTime(timeFormat, eventStartDateTime)
                        : '')
                    : playerUi!.title}
                </Typography>

                {(location.pathname === '/live' ||
                  location.pathname === '/sessionWelcome') &&
                  playerUi!.multiSession && (
                    <Typography
                      id="bs_Player_Header_Subline"
                      variant="h4"
                      component="h3"
                      color={theme.palette.playerBgColor.contrastText}
                      sx={{ px: mediaQueryTablet ? '16px' : 'unset' }}
                      pb={2}
                    >
                      {curLiveData?.sceneData?.general?.sessionTitle}
                    </Typography>
                  )}
              </Box>

              {playerUi!.multiSession &&
                (location.pathname === '/live' ||
                  location.pathname === '/sessionWelcome') && (
                  <Box>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => setSelectedSession('')}
                    >
                      Zur Lobby
                    </Button>
                  </Box>
                )}
            </Stack>
          </>
        )}
      </Container>
    </>
  );
}
