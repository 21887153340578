import Parse from 'parse';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { Suspense, lazy, useEffect, useState } from 'react';
import usePlayerStore from '../store/playerStore';
import useLiveStore from '../store/liveStore';
import useInterval from '../hooks/useInterval';
import useLivePlayerControl from '../hooks/useLivePlayerControl';
import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from '@mui/icons-material';
import setUserTrackingApi from '../api/user/setUserTrackingApi';
import useAgenda from '../hooks/useAgenda';
import rewriteHtml from '../util/htmlTextHelpers';

export default function PlayerContentLive() {
  // global store
  const {
    eventId,
    registrationData,
    playerUser,
    isPreview,
    selectedSession,
    previewSession,
  } = usePlayerStore();

  // select the session to display
  const sessionToDisplay = isPreview ? previewSession! : selectedSession!;

  const curLiveData = useLiveStore((state) => state.sessions[sessionToDisplay]);
  console.log('##!##!#-curLiveData', curLiveData);

  // get Agenda
  const { renderProgram, renderAgenda } = useAgenda({
    stage: 'live',
    variant: 'Widget',
  });

  // state
  const fullscreenEnabled = true;
  const [fullscreenActive, setFullscreenActive] = useState(false);

  // theme
  const theme = useTheme();

  // fullscreen handling
  const toggleFullscreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      setFullscreenActive(false);

      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      setFullscreenActive(true);

      const element = document.getElementById('bs_Player_FullscreenContainer');
      if (element!.requestFullscreen) {
        element!.requestFullscreen();
      } else if (element!.mozRequestFullScreen) {
        element!.mozRequestFullScreen();
      } else if (element!.webkitRequestFullscreen) {
        element!.webkitRequestFullscreen((Element as any).ALLOW_KEYBOARD_INPUT);
      } else if (element!.msRequestFullscreen) {
        element!.msRequestFullscreen();
      }
    }
  };

  const fullscreenChanged = () => {
    console.log('fullscreenChanged-called');
    if (document.fullscreenElement || document.webkitCurrentFullScreenElement) {
      console.log('fullscreenActive - true');
      setFullscreenActive(true);
    } else {
      console.log('fullscreenActive - false');
      setFullscreenActive(false);
    }
  };

  // player control
  const { sceneToDisplay } = useLivePlayerControl();

  // start tracking
  if (registrationData && playerUser && playerUser.email && !isPreview) {
    const trackingCall = async () => {
      setUserTrackingApi(eventId!.eventId, playerUser.email);
      // setUserTrackingApi(eventId.eventId, playerUser.email,selectedSession);
    };

    useInterval(trackingCall, 60000);
  }

  //// side effects
  useEffect(() => {
    //// Init tracking

    // set fullscreen listener
    const element = document.getElementById('bs_Player_FullscreenContainer');
    // element.addEventListener('fullscreenchange', fullscreenChanged);
    element!.addEventListener('fullscreenchange', fullscreenChanged, false);
    element!.addEventListener('mozfullscreenchange', fullscreenChanged, false);
    element!.addEventListener('MSFullscreenChange', fullscreenChanged, false);
    element!.addEventListener(
      'webkitfullscreenchange',
      fullscreenChanged,
      false,
    );

    return () => {
      // remove event listener
      const element = document.getElementById('e21z_fullscreenContainer');
      if (element !== null && element !== undefined) {
        // element.removeEventListener = fullscreenChanged;
        element.removeEventListener(
          'fullscreenchange',
          fullscreenChanged,
          false,
        );
        element.removeEventListener(
          'mozfullscreenchange',
          fullscreenChanged,
          false,
        );
        element.removeEventListener(
          'MSFullscreenChange',
          fullscreenChanged,
          false,
        );
        element.removeEventListener(
          'webkitfullscreenchange',
          fullscreenChanged,
          false,
        );
      }
    };
  }, []);
  return (
    <>
      <Box
        sx={{ width: '100%', color: theme.palette.playerBgColor.contrastText }}
      >
        {curLiveData?.sceneData?.general?.showDescriptionOnSessionBroadcast &&
          // <Typography
          //   sx={{
          //     p: {
          //       md: 2,
          //     },
          //     pb: { md: 0 },
          //   }}
          // >
          //   {curLiveData?.sceneData?.general?.sessionDescription}
          // </Typography>
          rewriteHtml(curLiveData?.sceneData?.general?.sessionDescription)}

        <Box
          id="bs_Player_FullscreenContainer"
          // sx={{
          //     backgroundColor: live_player_bg_color !== "" ? "#"+live_player_bg_color+" !important":"",
          // }}
          sx={{ backgroundColor: theme.palette.playerBgColor.main }}
        >
          <Box
            className={'fullscreenEnabled'}
            sx={{
              position: fullscreenActive ? 'absolute' : 'relative',
              width: '100%',
              height: fullscreenActive ? '100vh' : 'unset',
              top: fullscreenActive ? '50%' : 'unset',
              transform: fullscreenActive ? 'translateY(-50%)' : 'unset',
              mt: 0,
            }}
          >
            <Suspense>{sceneToDisplay}</Suspense>

            {fullscreenEnabled && (
              <Box
                id="e21z_fullscreenToggleBar"
                sx={{
                  width: '100%',
                  textAlign: 'right',
                  position: fullscreenActive ? 'fixed' : 'static',
                  bottom: fullscreenActive ? '0' : 'unset',
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="body1">
                    {fullscreenActive
                      ? 'Vollbild ausschalten'
                      : 'Vollbild einschalten'}
                  </Typography>

                  <IconButton
                    onClick={toggleFullscreen}
                    style={{
                      textAlign: 'right',
                    }}
                    color="primary"
                  >
                    {fullscreenActive ? (
                      <FullscreenExitIcon />
                    ) : (
                      <FullscreenIcon />
                    )}
                  </IconButton>
                </Stack>
              </Box>
            )}
          </Box>
        </Box>

        {/* display agenda */}
        {curLiveData?.sceneData?.general?.showAgendaOnSessionBroadcast &&
          renderAgenda(curLiveData?.id)}
      </Box>
    </>
  );
}
