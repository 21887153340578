import Parse from 'parse';
import usePlayerStore from '../../store/playerStore';

type validateEventAccessApiParams = { accessCode: string };

export default async function validateEventAccessApi(
  params: validateEventAccessApiParams,
) {
  const { accessCode } = params;

  // get eventid from global store
  const eventId = usePlayerStore.getState().eventId;

  //
  return Parse.Cloud.run('validateEventAccess', {
    eventId: eventId,
    accessCode: accessCode,
  });
}
