import { useEffect, useRef } from 'react';
import VideoJS from './VideoPlayer/VideoJS';
import { isValidUrl } from '../../util/urlHelper';

type VideoPlayerProps = {
  setVideoPlayerEnd?: (state: boolean) => void;
  code: string;
};

type videoJsOptions = {
  autoplay: boolean;
  controls: boolean;
  responsive: boolean;
  muted: boolean;
  fluid: boolean;
  sources: any[];
  techOrder?: string[];
  controlBar?: any;
};

export default function MediaEmbedPlayer(props: VideoPlayerProps) {
  const { code } = props;

  // const { setVideoPlayerEnd } = props.params;

  const setVideoPlayerEnd = (state: boolean) => {};

  // create videojs ref
  const playerRef = useRef(null);

  //// build the videojs config object
  // basic object & defaults
  const videoJsOptions: videoJsOptions = {
    autoplay: true,
    // autoplay: "muted",
    controls: true,
    responsive: true,
    muted: false,
    fluid: true,
    sources: [{}],
  };

  // get the player URL
  let playerUrl;
  if (isValidUrl(code)) {
    playerUrl = new URL(code);
  } else {
    throw new Error('Invalid Player Url provided!');
  }

  if (
    playerUrl.host.includes('youtube') ||
    playerUrl.host.includes('youtu.be')
  ) {
    //// so, it's a youtube video
    // some standard settings
    videoJsOptions.techOrder = ['youtube'];
    videoJsOptions.controlBar = {
      fullscreenToggle: false,
      pictureInPictureToggle: false,
    };
    videoJsOptions.sources[0].type = 'video/youtube';
    videoJsOptions.sources[0].youtube = {};
    videoJsOptions.sources[0].youtube.fs = 0;
    videoJsOptions.sources[0].youtube.showinfo = 0;
    videoJsOptions.sources[0].youtube.rel = 0;
    videoJsOptions.sources[0].youtube.modestbranding = 0;

    // set fullscreen
    if (playerUrl.searchParams.get('autoplay')) {
      videoJsOptions.autoplay = Boolean(
        Number(playerUrl.searchParams.get('autoplay')),
      );
      // Boolean(Number(playerUrl.searchParams.get("autoplay"))) ? videoJsOptions.autoplay="muted" : videoJsOptions.autoplay=false;

      videoJsOptions.sources[0].youtube.autoplay =
        playerUrl.searchParams.get('autoplay');
    }

    // set controls
    if (playerUrl.searchParams.get('controls')) {
      videoJsOptions.controls =
        // playerUrl.searchParams.get('controls') > 0 ? true : false;
        playerUrl.searchParams.get('controls') ? true : false;
      videoJsOptions.sources[0].youtube.controls =
        playerUrl.searchParams.get('controls');
    }

    // set fullscreen
    if (playerUrl.searchParams.get('fs')) {
      videoJsOptions.controlBar.fullscreenToggle = Boolean(
        Number(playerUrl.searchParams.get('fs')),
      );
      videoJsOptions.sources[0].youtube.fs = playerUrl.searchParams.get('fs');
    }

    // set source
    videoJsOptions.sources[0].src = playerUrl.origin + playerUrl.pathname;

    if (playerUrl.searchParams.get('v'))
      videoJsOptions.sources[0].src =
        videoJsOptions.sources[0].src + '?v=' + playerUrl.searchParams.get('v');
  } else if (playerUrl.host.includes('vimeo')) {
    //// so, it's a vimeo video
    // some standard settings
    videoJsOptions.techOrder = ['vimeo'];
    videoJsOptions.controlBar = {
      fullscreenToggle: false,
      pictureInPictureToggle: false,
    };
    videoJsOptions.sources[0].type = 'video/vimeo';
    videoJsOptions.sources[0].vimeo = {};
    videoJsOptions.sources[0].vimeo.byline = 0;
    videoJsOptions.sources[0].vimeo.dnt = 1;
    videoJsOptions.sources[0].vimeo.muted = 0;
    videoJsOptions.sources[0].vimeo.pip = 0;
    videoJsOptions.sources[0].vimeo.title = 0;

    // set autoplay
    if (playerUrl.searchParams.get('autoplay')) {
      videoJsOptions.autoplay =
        typeof playerUrl.searchParams.get('autoplay') === 'boolean'
          ? Boolean(playerUrl.searchParams.get('autoplay'))
          : Boolean(Number(playerUrl.searchParams.get('autoplay')));
      // const autoplay = (typeof playerUrl.searchParams.get("autoplay") === "boolean") ? playerUrl.searchParams.get("autoplay") :  Boolean(Number(playerUrl.searchParams.get("autoplay")));
      // autoplay ?videoJsOptions.autoplay="muted" : videoJsOptions.autoplay=false;
      videoJsOptions.sources[0].vimeo.autoplay =
        playerUrl.searchParams.get('autoplay');
    }

    // set controls
    if (playerUrl.searchParams.get('controls')) {
      videoJsOptions.controls =
        typeof playerUrl.searchParams.get('controls') === 'boolean'
          ? Boolean(playerUrl.searchParams.get('controls'))
          : Boolean(Number(playerUrl.searchParams.get('controls')));
      videoJsOptions.sources[0].vimeo.controls =
        playerUrl.searchParams.get('controls');
    }

    // set source
    videoJsOptions.sources[0].src = playerUrl.origin + playerUrl.pathname;
  } else {
    //// guess it's a regular http mp4
    videoJsOptions.controlBar = {
      fullscreenToggle: false,
      pictureInPictureToggle: false,
    };
    videoJsOptions.sources[0].type = 'video/mp4';

    videoJsOptions.sources[0].src = playerUrl.origin + playerUrl.pathname;
  }

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    //// player events handling here
    // player.on('waiting', () => {
    //     console.log('player is waiting');
    // });

    // player.on('dispose', () => {
    //     console.log('player will dispose');
    // });

    player.on('ended', () => {
      setVideoPlayerEnd(true);
    });

    // player.on('ready', () => {
    //     console.log('player ready');
    // });
  };

  useEffect(() => {
    setVideoPlayerEnd(false);
  }, []);

  return <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />;
  // console.log('###!!!-videoJsOptions', videoJsOptions);

  // return <>TEST</>;
}
