import { useQuery, useQueryClient } from '@tanstack/react-query';
import getEventPlayerUserApi from '../api/user/getEventPlayerUserApi';
import getEventPlayerUserByAccessTokenApi from '../api/user/getEventPlayerUserByAccessTokenApi';
import { bool } from 'yup';

export function useEventPlayerUserQuery(eventId: string, userId: string) {
  const queryClient = useQueryClient();

  return useQuery(
    {
      queryKey: ['player', 'user'],
      queryFn: () =>
        getEventPlayerUserApi({ eventId: eventId, userId: userId }),
    },
    queryClient,
  );
}

export function useEventPlayerUserByAccessTokenQuery(
  eventId: string,
  personalAccessCode: string,
  queryEnabled?: boolean,
) {
  const queryClient = useQueryClient();
  console.log(
    'useEventPlayerUserByAccessTokenQueryCALLED',
    useEventPlayerUserByAccessTokenQuery,
  );

  return useQuery(
    {
      queryKey: ['player', 'user'],
      queryFn: () =>
        getEventPlayerUserByAccessTokenApi({
          eventId: eventId,
          personalAccessCode: personalAccessCode,
        }),
      enabled: queryEnabled === undefined ? true : queryEnabled,
    },
    queryClient,
  );
}
