import { Box } from '@mui/material';
import MainLayout from '../Layout/MainLayout';
import PlayerContentAnnounce from '../Components/PlayerContentAnnounce';
import PlayerContentLive from '../Components/PlayerContentLive';
import PlayerContentClosed from '../Components/PlayerContentClosed';
import PlayerContentRegistration from '../Components/PlayerContentRegistration';
import PlayerContentConfirmation from '../Components/PlayerContentConfirmation';
import PlayerContentWelcome from '../Components/PlayerContentWelcome';
import PlayerContentLobby from '../Components/PlayerContentLobby';
import PlayerContentAgenda from '../Components/PlayerContentAgenda';
import PlayerContentSessionWelcome from '../Components/PlayerContentSessionWelcome';

export default function routesConfig() {
  return [
    {
      path: '/',
      //   lazy: () => import('./routes/root'),
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: <PlayerContentAnnounce />,
        },
        {
          path: '/registration',
          element: <PlayerContentRegistration />,
        },
        {
          path: '/confirm',
          element: <PlayerContentConfirmation />,
        },
        {
          path: '/welcome',
          element: <PlayerContentWelcome />,
        },
        {
          path: '/lobby',
          element: <PlayerContentLobby />,
        },
        {
          path: '/sessionWelcome',
          element: <PlayerContentSessionWelcome />,
        },
        {
          path: '/live',
          element: <PlayerContentLive />,
        },
        {
          path: '/closed',
          element: <PlayerContentClosed />,
        },
      ],
    },
    {
      path: '/agenda',
      element: <PlayerContentAgenda />,
    },
  ];
}
