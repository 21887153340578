import { Theme } from '@mui/material';

export default function MuiTabs(theme: Theme) {
  return {
    // components: {
    MuiTabs: {
      styleOverrides: {
        root: { minHeight: 0 },
        scroller: {
          '.MuiButtonBase-root.MuiTab-root': {
            fontSize: '0.75rem',
            padding: '8px 12px',
            textTransform: 'none',
          },
        },
        indicator: { display: 'none' },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          // textTransform: 'none',
          fontSize: theme.typography.body2.fontSize,
          paddingTop: '8px',
          paddingBottom: '8px',
          minHeight: 0,
        },
      },
    },

    MuiTabScrollButton: { styleOverrides: { root: { width: 'unset' } } },
    // },
  };
}
