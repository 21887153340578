import { useForm } from 'react-hook-form';
import usePlayerStore from '../../store/playerStore';
import ConfirmationFormLayout from './ConfirmationFormLayout';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  confirmationDefaults,
  confirmationValSchema,
} from './confirmationValidationSchema';
import { Box } from '@mui/material';
import { ConfirmationFormTypes } from './confirmationFormTypes';
import { useMutation } from '@tanstack/react-query';
import setEventPlayerUserApi from '../../api/user/setEventPlayerUserApi';
import validateEventAccessApi from '../../api/event/validateEventAccessApi';
import {
  useEventPlayerUserByAccessTokenQuery,
  useEventPlayerUserQuery,
} from '../../data/user.query';
import { useEffect, useState } from 'react';
import confirmEventPlayerUserApi from '../../api/user/confirmEventPlayerUserApi';
import { useFeedback } from '../Feedback/FeedbackContext';
import {
  redirect,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

export default function ConfirmationForm() {
  // params

  // global store
  const {
    eventId,
    playerUser,
    setPlayerUser,
    registrationData,
    onlineStageData,
    isPreview,
  } = usePlayerStore();

  // state
  const [userLoaded, setUserLoaded] = useState(false);
  let extendedRegistrationDefaults = {
    ...confirmationDefaults,
    fieldDefinition: registrationData!.fieldDefinition,
    // accessCodeRequired: onlineStageData.accessCodeRequired,
  };

  // feedback
  const { setFeedback } = useFeedback();

  // navigation
  const navigate = useNavigate();
  const location = useLocation();

  // get user
  const {
    data: userData,
    error,
    isLoading,
    isSuccess,
    isError,
    status,
  } = useEventPlayerUserByAccessTokenQuery(
    eventId!.eventId,
    isPreview ? '' : playerUser!.uct!,
  );

  // data mutation // confirm user
  const confirmEventPlayerUser = useMutation({
    mutationFn: (data: any) => confirmEventPlayerUserApi(data),
    onSuccess: (data, variables, context) => {
      if (data) {
        localStorage.setItem(
          'bsLocalUser-' + eventId!.eventId,
          JSON.stringify(data),
        );
        setPlayerUser(data.attributes);
      } else {
      }
    },
    onError: (error, variables, context) => {},
  });

  // react hook form
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    defaultValues: extendedRegistrationDefaults,
    resolver: yupResolver(confirmationValSchema),
  });

  // functions
  const handleFormSubmit = async (data: ConfirmationFormTypes) => {
    // check accescode
    if (!isPreview) {
      confirmEventPlayerUser.mutate({
        data: data as any,
        eventId: eventId,
        personalAccessCode: playerUser!.uct,
      });
    }
  };

  // side effects
  useEffect(() => {
    if (userData) {
      extendedRegistrationDefaults = {
        ...userData.attributes,
        fieldDefinition: registrationData!.fieldDefinition,
        // accessCodeRequired: onlineStageData.accessCodeRequired,
      };

      reset({
        ...userData.attributes,
        fieldDefinition: registrationData!.fieldDefinition,
        // accessCodeRequired: onlineStageData.accessCodeRequired,
      });
      setUserLoaded(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError && !isPreview) {
      setFeedback({
        type: 'error',
        message: 'Benutzer in diesem Event nicht gefunden',
        callbackCancel: () => {
          window.location.href = '/' + location.search;
        },
      });
    }
  }, [isError]);

  return userLoaded || isPreview ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, pt: 4, pb: 4 }}>
      <Box maxWidth="480px">
        <ConfirmationFormLayout
          handleSubmit={handleSubmit}
          handleFormSubmit={handleFormSubmit}
          control={control}
          errors={errors}
          trigger={trigger}
          setValue={setValue}
          getValues={getValues}
          watch={watch}
        />
      </Box>
    </Box>
  ) : (
    <></>
  );
}
