import {
  ReactNode,
  createContext,
  startTransition,
  useContext,
  useMemo,
  useState,
} from 'react';

//// TYPEDEFS
type FeedbackProviderProps = {
  children: ReactNode;
};

type FeedbackType = {
  headline?: string | undefined;
  message?: string;
  errorCode?: Parse.Error | Error | undefined; // ACHTUNG: IM MOMENT WERDEN HIER NUR PARSE FEHLER BEHANDELT!!!
  type: 'error' | 'success' | 'info' | 'warning' | 'loading' | 'confirmAction';
  autoclose?: boolean;
  callbackCancel?: ((...args: any[]) => any) | undefined;
  callback?: ((...args: any[]) => any) | undefined;
};

type FeedbackContextType = {
  feedback: FeedbackType | null;
  setFeedback: React.Dispatch<React.SetStateAction<FeedbackType | null>>;
};

//// COMPONENT
const FeedbackContext = createContext<FeedbackContextType | null>(null);

export function FeedbackProvider({ children }: FeedbackProviderProps) {
  const [feedback, setFeedback] = useState<FeedbackType | null>(null);

  // const sendFeedback = (fb: FeedbackType) => {
  //   startTransition(() => {
  //     setFeedback(fb);
  //   });
  // };

  const value = useMemo(
    () => ({
      feedback,
      setFeedback,
    }),
    [feedback],
  );

  return (
    <FeedbackContext.Provider value={value}>
      {children}
    </FeedbackContext.Provider>
  );
}

export const useFeedback = (): FeedbackContextType => {
  const context = useContext(FeedbackContext);

  // make sure we return a valid context
  if (!context) {
    throw new Error('useFeedback must be used within a FeedbackProvider');
  }

  return context;
};
