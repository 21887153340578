import {
  Box,
  Button,
  Container,
  IconButton,
  Modal,
  Paper,
  Stack,
  Theme,
  ThemeProvider,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import usePlayerStore from '../store/playerStore';
import { default as Grid } from '@mui/material/Unstable_Grid2/Grid2';
import { formatDate, formatTime } from '../util/dateTimeFormats';
import useLiveStore from '../store/liveStore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useLocation } from 'react-router-dom';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { adjustGrayBasedOnBrightness } from '../util/colorHelper';

type useAgendaProps = {
  stage: 'preview' | 'live' | 'marketing';
  variant?: 'Component' | 'Widget';
  theme?: Theme;
};

export default function useAgenda(props: useAgendaProps) {
  // props
  const { stage, variant = 'Component' } = props;

  // global store
  const { agenda, isPreview, playerUi, selectedSession, setSelectedSession } =
    usePlayerStore();

  // const { liveDataSession } = useLiveStore();

  // console.log('##!##-liveDataSession', liveDataSession);

  const {
    dividerActors,
    timeFormat,
    dateFormat,
    programHeadline,
    agendaHeadline,
    slotTitleInSecondaryColor,
  } = playerUi!;

  // navigation
  const location = useLocation();

  // theme
  const theme = useTheme();
  console.log('##!##!#-theme', theme);

  //
  const getActorDivider = () => {
    return dividerActors ? dividerActors : ' | ';
  };

  // set the mode to display
  const agendaStage = isPreview ? 'preview' : stage;

  // get slots for session
  const getSessionSlots = (sessionId: string) => {
    const sessionSlots = agenda[agendaStage].sessions.filter(
      (ses: any, index: number) => ses?.[0].sessionId === sessionId,
    );

    if (sessionSlots && sessionSlots?.[0]?.length > 0) {
      return sessionSlots[0];
    }

    return undefined;
  };

  // segment the slots into columns
  const getSegmentedSlots = (sessionId: string) => {
    const slots = getSessionSlots(sessionId);

    console.log('##!###-getSegmentedSlots', sessionId, slots);

    const segments: any[] = [];
    let segment: any[] = [];

    slots?.forEach((slot: any, index: number) => {
      segment.push(slot);

      if (variant !== 'Widget') {
        if (slot.newColumnAfter || index === slots.length - 1) {
          segments.push([...segment]);
          segment = [];
        }
      } else if (index === slots.length - 1) {
        segments.push([...segment]);
      }
      // }
    });
    console.log('segments', segments);
    return segments;
  };

  // build program
  const buildProgramHtml = () => {
    // check if necessary data is available
    if (!agenda || !agenda[agendaStage] || !agenda[agendaStage].event) {
      return <></>;
    }

    // sort agenda by time and split by date
    const sortedSessions = groupAndSortEvents(agenda[agendaStage].event);

    console.log('##!##!-sortedSessions', sortedSessions);

    // Program modal handling
    const [selectedSessionForProgram, setSelectedSessionForProgram] =
      useState();
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModalOpen = () => {
      setModalOpen((state) => !state);
    };

    // get the sessions agenda data
    const getSessionAgendaData = (sessionId?: string) => {
      let selectedSessionAgendaData;

      if (!sessionId) {
        selectedSessionAgendaData = agenda[agendaStage].event[0];
      } else {
        selectedSessionAgendaData = agenda[agendaStage].event.find(
          (session: any) => session.id === sessionId,
        );
      }

      console.log(
        '##!##!#-selectedSessionAgendaData',
        selectedSessionAgendaData,
      );

      return selectedSessionAgendaData;
    };

    // get session state
    const getSessionState = (sessionId: string) => {
      // const sessionLiveData = liveDataSession.find(
      //   (ses: any) => ses.id === sessionId,
      // );

      const sessionLiveData = useLiveStore(
        (state) => state.sessions[sessionId],
      );

      if (sessionLiveData) return sessionLiveData.status;

      return 'stop';
    };

    return (
      <>
        {/* <ThemeProvider theme={theme}> */}
        <Box sx={{ mt: '1rem' }}>
          <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold' }}>
            {programHeadline}
          </Typography>

          {sortedSessions.map((days: any, dayIndex: number) => {
            {
              return (
                <>
                  {sortedSessions.length > 1 && (
                    <Typography
                      key={'day-' + dayIndex}
                      variant="h6"
                      sx={{ mb: 2 }}
                    >
                      {/* {`Tag ${dayIndex + 1} (${days[0].startdate.toLocaleDateString()})`} */}
                      {`Tag ${dayIndex + 1} (${formatDate(dateFormat, days[0].startdate)})`}
                    </Typography>
                  )}

                  {/* <Stack
                    direction="row"
                    sx={{ mb: 2, width: 'calc(100% + 16px)', ml: -2 }}
                  > */}
                  <Box
                    sx={{
                      mb: 2,
                      display: 'grid',
                      gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
                      // gridAutoRows: 'minmax(auto, max-content)',
                      gridAutoRows: 'max-content',
                      columnGap: '16px',
                      rowGap: '16px',
                    }}
                  >
                    {days.map((session: any, sessionIndex: number) => {
                      return (
                        <>
                          <Stack
                            sx={{
                              p: 2,
                              border: `1px solid ${theme.palette.playerBgColor.contrastText}`,
                              borderRadius: 2,
                            }}
                            direction="row"
                          >
                            <Stack
                              direction="row"
                              sx={{ minHeight: '216px', height: '100%' }}
                            >
                              <Stack
                                direction="column"
                                alignItems="center"
                                // justifyContent="space-between"
                                sx={{
                                  pr: 2,
                                  borderRight: `1px solid ${theme.palette.playerBgColor.contrastText}`,
                                  width: '80px',
                                }}
                              >
                                <Box>
                                  <Typography variant="h6" textAlign="center">
                                    {formatTime(timeFormat, session.starttime)}
                                  </Typography>
                                  <Typography variant="h6" textAlign="center">
                                    -
                                  </Typography>
                                  <Typography variant="h6" textAlign="center">
                                    {formatTime(timeFormat, session.endtime)}
                                  </Typography>
                                </Box>

                                <IconButton
                                  onClick={() => {
                                    setSelectedSessionForProgram(session.id);
                                    setModalOpen(true);
                                  }}
                                  sx={{
                                    mt: 4,
                                    color:
                                      theme.palette.playerBgColor.contrastText,
                                  }}
                                >
                                  <InfoOutlinedIcon />
                                </IconButton>

                                {getSessionState(session.id) === 'stop' &&
                                  location.pathname.includes('lobby') &&
                                  // getSessionState(session.id) !== 'ended' &&
                                  session.hasOnlineStage && (
                                    <Button
                                      size="small"
                                      variant="contained"
                                      disabled={false}
                                      sx={{
                                        mt: 4,
                                        // backgroundColor:
                                        //   theme.palette.slotBorder.main,
                                        backgroundColor:
                                          adjustGrayBasedOnBrightness(
                                            theme.palette.playerBgColor.main,
                                          ),
                                        color: theme.palette.playerBgColor.main,
                                        '&:hover': {
                                          backgroundColor:
                                            adjustGrayBasedOnBrightness(
                                              theme.palette.playerBgColor.main,
                                            ),
                                          opacity: 0.85,
                                          color:
                                            theme.palette.playerBgColor.main,
                                        },
                                      }}
                                      onClick={() =>
                                        setSelectedSession(session.id)
                                      }
                                    >
                                      Play
                                    </Button>
                                  )}

                                {getSessionState(session.id) === 'play' &&
                                  location.pathname.includes('lobby') &&
                                  // getSessionState(session.id) !== 'ended' &&
                                  session.hasOnlineStage && (
                                    <Button
                                      size="small"
                                      variant="contained"
                                      disabled={false}
                                      sx={{
                                        mt: 4,
                                        backgroundColor:
                                          theme.palette.error.main,
                                        color: theme.palette.error.contrastText,
                                        '&:hover': {
                                          backgroundColor:
                                            theme.palette.error.main,
                                          opacity: 0.85,
                                          color:
                                            theme.palette.error.contrastText,
                                        },
                                      }}
                                      onClick={() =>
                                        setSelectedSession(session.id)
                                      }
                                    >
                                      Play
                                    </Button>
                                  )}

                                {getSessionState(session.id) === 'ended' &&
                                  location.pathname.includes('lobby') &&
                                  // getSessionState(session.id) !== 'ended' &&
                                  session.hasOnlineStage && <></>}
                              </Stack>

                              <Stack sx={{ pl: 2 }}>
                                <Typography
                                  variant="h3"
                                  color={
                                    slotTitleInSecondaryColor ? 'secondary' : ''
                                  }
                                >
                                  {session.title}
                                </Typography>

                                {session.showDescriptionInProgram && (
                                  <Typography variant="body2">
                                    {session.description}
                                  </Typography>
                                )}

                                {session.showActorsInProgram &&
                                  session.actors.length > 0 && (
                                    <>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ mt: 1, fontWeight: 'bold' }}
                                      >
                                        Moderation:
                                      </Typography>

                                      {session.actors.map(
                                        (actor: any, indexActor: number) => (
                                          <>
                                            <Typography
                                              variant="body2"
                                              sx={{ pb: '2px' }}
                                            >
                                              {(actor.firstname
                                                ? actor.firstname
                                                : '') +
                                                (actor.lastname
                                                  ? ' ' + actor.lastname
                                                  : '') +
                                                '' +
                                                (actor.title
                                                  ? getActorDivider() +
                                                    actor.title
                                                  : '') +
                                                (actor.organisation
                                                  ? getActorDivider() +
                                                    actor.organisation
                                                  : '')}
                                            </Typography>
                                          </>
                                        ),
                                      )}
                                    </>
                                  )}
                              </Stack>
                            </Stack>
                          </Stack>
                        </>
                      );
                    })}
                  </Box>
                  {/* </Stack> */}
                </>
              );
            }
          })}
        </Box>

        <Modal open={modalOpen}>
          <Paper
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: theme.palette.playerBgColor.main,
              color: theme.palette.playerBgColor.contrastText,
              [theme.breakpoints.down(1200)]: { maxWidth: 'calc(100% - 48px)' },
              [theme.breakpoints.down(800)]: { maxWidth: '100svw' },
              maxWidth: { md: '1104px' },
              width: '100%',
            }}
          >
            <Box
              width="100%"
              height="100%"
              sx={{
                // backgroundColor: backgroundColor ? backgroundColor : '',
                // maxHeight: { xs: '100svh', sm: '100vh' },
                // minHeight: { xs: 'unset', sm: '100vh' },
                // width: { xs: '100svw', sm: 'calc(100% - 48px)' },
                width: { xs: '100svw', sm: '100%' },
                height: { xs: '100svh', sm: 'calc(100vh - 48px)' },
                maxHeight: { xs: '100svh', sm: '700px' },
                // maxWidth: { xs: '100svw', sm: '1200px' },
                overflowY: 'auto',
                overflowX: { xs: 'hidden', sm: 'unset' },
              }}
            >
              <Toolbar
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <IconButton
                  onClick={toggleModalOpen}
                  sx={{
                    position: 'absolute',
                    top: '0px',
                    right: '0px',
                    color: theme.palette.playerBgColor.contrastText,
                  }}
                >
                  <CloseOutlinedIcon
                  // sx={{ color: contrastTextColor }}
                  />
                </IconButton>
              </Toolbar>
              <Container>
                <Box
                  sx={{
                    px: 4,
                    py: 0,
                    height: 'calc(100% - 74px)',
                  }}
                >
                  <Typography variant="h3">
                    {getSessionAgendaData(selectedSessionForProgram)
                      ? getSessionAgendaData(selectedSessionForProgram)?.title
                      : ''}
                  </Typography>
                  {buildAgendaHtml(selectedSessionForProgram)}
                </Box>
              </Container>
            </Box>
          </Paper>
        </Modal>
        {/* </ThemeProvider> */}
      </>
    );
  };

  // build Agenda
  const buildAgendaHtml = (sessionId?: string) => {
    console.log('##!##!#-agenda', agenda);

    // check if necessary data is available
    if (!agenda || !agenda[agendaStage] || !agenda[agendaStage].event) {
      return <></>;
    }

    console.log('##!##!-sessionId', sessionId);

    let selectedSessionIdForAgenda = selectedSession;
    let selectedSessionForAgenda;

    if (sessionId) {
      selectedSessionIdForAgenda = sessionId;
    }

    if (!selectedSessionIdForAgenda) {
      selectedSessionForAgenda = agenda[agendaStage].event[0];
    } else {
      selectedSessionForAgenda = agenda[agendaStage].event.find(
        (session: any) => session.id === selectedSessionIdForAgenda,
      );
    }

    console.log('##!##!-selectedSessionForAgenda', selectedSessionForAgenda);

    return (
      <>
        <style>{`
            .bs_agenda.sessionGridContainer {
              display: grid;
              grid-template-columns: [first] auto [second] 1fr;
              column-gap: 16px;
              width: 100%;
            }

            .bs_agenda.sessionGridItemDate {
              grid-column-start: 1;
            }

            .bs_agenda.sessionGridItemContent {
              grid-column-start: 2;
            }

            .bs_agenda.slotGridContainer {
              display: grid;
              grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
              width: 100%;
              column-gap: 24px;
            }

            .bs_agenda.slotColumn {}

            .bs_agenda.slotGrid {
              display: grid;
              grid-template-columns: [first] auto [second] 1fr;
              grid-template-rows: min-content;
              column-gap: 16px;
              width: 100%;
            }
        `}</style>

        <Box sx={{ mt: '1rem' }}>
          {
            // agenda[agendaStage].event[selectedSession || 0]
            selectedSessionForAgenda?.showSessionDescriptionInAgenda && (
              <>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  {/* {agenda[agendaStage].event[selectedSession || 0].description} */}
                  {selectedSessionForAgenda?.description}
                </Typography>
              </>
            )
          }

          <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
            {agendaHeadline}
          </Typography>

          <div className="bs_agenda slotGridContainer">
            {getSegmentedSlots(
              // agenda[agendaStage].event[selectedSession || 0].id,
              selectedSessionForAgenda?.id,
            ).map((segment, segIndex) => {
              // return the columns
              return (
                // <div key={segIndex} className="bs_agenda slotColumn">
                <div key={'segment-' + segIndex} className="bs_agenda slotGrid">
                  {segment.map((slot: any, slotIndex: number) => (
                    // return the slots
                    <>
                      {slot.showInAgenda && (
                        <>
                          <Typography
                            variant="body1"
                            sx={{ textAlign: 'right' }}
                          >
                            {/* {new Date(slot.starttime).toLocaleTimeString(
                                    'de-DE',
                                    {
                                      hour: '2-digit',
                                      minute: '2-digit',
                                      hour12: false,
                                    },
                                  )} */}
                            {formatTime(timeFormat, slot.starttime)}
                          </Typography>
                          <Stack mb={1}>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 'bold', pb: 1 }}
                              color={
                                slotTitleInSecondaryColor ? 'secondary' : ''
                              }
                            >
                              {slot.title}
                            </Typography>

                            {slot.showDescriptionInAgenda && (
                              <Typography variant="body1" sx={{ pb: 1 }}>
                                {slot.description}
                              </Typography>
                            )}

                            {slot.showActorsInAgenda && (
                              <>
                                {slot.actors.map(
                                  (actor: any, actorIndex: number) => {
                                    // check the actors settings for this slot
                                    const actorOptionsIndex =
                                      slot?.actorOptions?.findIndex(
                                        (actObj: any) =>
                                          actObj.actorId === actor.objectId,
                                      );

                                    // check if actor should be displayed
                                    let showActor = false;

                                    if (
                                      actorOptionsIndex !== undefined &&
                                      actorOptionsIndex !== -1
                                    ) {
                                      showActor =
                                        slot?.actorOptions[actorOptionsIndex]
                                          .displayActorInAgenda;
                                    }

                                    return showActor ? (
                                      <Typography
                                        variant="body1"
                                        sx={{ pb: '2px' }}
                                        key={
                                          'actor-' + slot.id + '-' + actorIndex
                                        }
                                      >
                                        {(actor.firstname
                                          ? actor.firstname + ' '
                                          : '') +
                                          (actor.lastname
                                            ? actor.lastname + ''
                                            : '') +
                                          (actor.title
                                            ? getActorDivider() +
                                              actor.title +
                                              ''
                                            : '') +
                                          (actor.organisation
                                            ? getActorDivider() +
                                              actor.organisation
                                            : '')}
                                      </Typography>
                                    ) : (
                                      <></>
                                    );
                                  },
                                )}
                              </>
                            )}
                          </Stack>
                          {/* </div> */}
                        </>
                      )}
                    </>
                  ))}
                </div>
              );
            })}
          </div>

          {/* {agenda[agendaStage].event[selectedSession || 0]
            .showSessionDescriptionInAgenda && (
            <>
              <Typography variant="body1">
                {agenda[agendaStage].event[selectedSession || 0].description}
              </Typography>
            </>
          )} */}

          {
            // agenda[agendaStage].event[selectedSession || 0]
            selectedSessionForAgenda?.showSessionActorsInAgenda && (
              <div
                className="bs_agenda slotGridContainer"
                style={{ marginTop: '1rem' }}
              >
                <div className="bs_agenda slotGrid">
                  {// agenda[agendaStage].event[selectedSession || 0].actors.map(
                  selectedSessionForAgenda?.actors.map(
                    (actor: any, index: number) => (
                      <>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {index === 0 ? 'Moderation:' : ''}
                        </Typography>
                        <Typography variant="body1" sx={{ pb: '2px' }}>
                          {(actor.firstname ? actor.firstname + ' ' : '') +
                            (actor.lastname ? actor.lastname + '' : '') +
                            (actor.title
                              ? getActorDivider() + actor.title + ''
                              : '') +
                            (actor.organisation
                              ? getActorDivider() + actor.organisation
                              : '')}
                        </Typography>
                      </>
                    ),
                  )}
                </div>
              </div>
            )
          }

          {/* Moderation */}
          {/* <Stack direction="row" spacing={1}>
            <Typography variant="body1">Moderation:</Typography>
            <Box></Box>
          </Stack> */}
        </Box>
        {/* );} */}
        {/* })} */}
      </>
    );
  };

  return { renderProgram: buildProgramHtml, renderAgenda: buildAgendaHtml };
}

function groupAndSortEvents(events: any[]): any[][] {
  // Step 1: Group events by startdate
  const groupedEvents: { [key: string]: any[] } = events.reduce(
    (groups, event) => {
      if (!event.showInProgram) return groups; // Ignore sessions where showInProgram is false

      const dateKey = event.startdate.toDateString(); // Convert Date to a string key
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(event);
      return groups;
    },
    {} as { [key: string]: any[] },
  );

  // Step 2: Sort each group by starttime
  for (const dateKey in groupedEvents) {
    console.log('##!##!#-', groupedEvents[dateKey][0].starttime.getTime());

    groupedEvents[dateKey].sort((a, b) => {
      const aTime = a.starttime.getHours() * 60 + a.starttime.getMinutes();
      const bTime = b.starttime.getHours() * 60 + b.starttime.getMinutes();
      return aTime - bTime;
    });
  }

  // Step 3: Convert the groups into an array and sort by startdate
  const sortedGroups = Object.keys(groupedEvents)
    .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    .map((dateKey) => groupedEvents[dateKey]);

  return sortedGroups;
}
