type getEventConnectionDataApiParams = { eventId: string };

export default async function getEventConnectionDataApi(
  params: getEventConnectionDataApiParams,
) {
  // params
  const { eventId } = params;

  // the request headers
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    'X-Parse-Application-Id': process.env.REACT_APP_PARSE_APP_ID!,
    'X-Parse-REST-API-Key': process.env.REACT_APP_PARSE_REST_KEY!,
    'X-Parse-Revocable-Session': '1',
    // 'X-Parse-Installation-Id': installationId!,
  };

  return await fetch(
    process.env.REACT_APP_PARSE_SERVER_URL + '/functions/getEventDataPlayer',
    {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: headers,
      body: JSON.stringify({ eventId: eventId }),
    },
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Fehler bei der Abfrage der Eventdaten');
    })
    .catch((e) => {
      console.error(e);
      throw new Error('Fehler bei der Abfrage der Eventdaten');
    });
}
