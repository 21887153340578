import { Box, Typography, useTheme } from '@mui/material';
import usePlayerStore from '../store/playerStore';
import rewriteHtml from '../util/htmlTextHelpers';
import useAgenda from '../hooks/useAgenda';
import Countdown from './Countdown/Countdown';

export default function PlayerContentLobby() {
  // global store
  const { playerUi, onlineStageData } = usePlayerStore();

  const { showEventText, showAgenda, showLobbyText, showCountDown } =
    onlineStageData!;

  // theme
  const theme = useTheme();

  // get Agenda
  // const Agenda = useAgenda({ stage: 'live' });
  const { renderProgram, renderAgenda } = useAgenda({ stage: 'live' });

  return (
    <Box
      width="100%"
      pt={1}
      sx={{ color: theme.palette.playerBgColor.contrastText }}
    >
      {showEventText &&
        // <Typography
        //   component="div"
        //   dangerouslySetInnerHTML={{ __html: playerUi.descriptionHtml }}
        // />
        rewriteHtml(playerUi!.descriptionHtml)}
      {/* <Typography
        component="div"
        dangerouslySetInnerHTML={{ __html: onlineStageData.playerText }}
      /> */}
      {showLobbyText && rewriteHtml(onlineStageData!.playerText)}

      {showCountDown && <Countdown targetDate={playerUi!.eventStartDateTime} />}

      {/* {showAgenda && (
        <Box sx={{ mb: 0 }}>
          {playerUi.multiSession ? renderProgram() : renderAgenda()}
        </Box>
      )} */}

      <Box sx={{ mb: 0 }}>{renderProgram()}</Box>
    </Box>
  );
}
