import { Theme } from '@mui/material';

export default function MuiAccordion(theme: Theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.MuiPaper-root': {
            border: 'none !important',
            boxShadow: 'none',
            backgroundImage: 'none',
            backgroundColor: 'transparent',
          },
          '&.Mui-expanded': { margin: 0, minHeight: 'unset' },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: { '&.Mui-expanded': { minHeight: 'unset' } },
        content: { '&.Mui-expanded': { margin: '12px 0' } },
      },
    },
    // MuiPaper: {
    //   styleOverrides: {
    //     root: { '&.MuiAccordion-root': { border: 'none !important' } },
    //   },
    // },
  };
}
