import * as yup from 'yup';
import i18n from 'i18next';
import { AccessCodeFormTypes } from './accessCodeFormTypes';
import usePlayerStore from '../../store/playerStore';

export const accessCodeValSchema = yup.object({
  personalAccessCode: yup.string().required(),
});

export const accessCodeDefaults: AccessCodeFormTypes = {
  personalAccessCode: '',
};
