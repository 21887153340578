import {
  Box,
  IconButton,
  Modal,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import rewriteHtml from '../util/htmlTextHelpers';

type LegalModalProps = {
  open: boolean;
  closeHandler: () => void;
  content: string;
  headline: string;
};

export default function LegalModal(props: LegalModalProps) {
  // props
  const { open, closeHandler, content, headline } = props;

  //
  const theme = useTheme();

  return (
    <Modal open={open} onClose={closeHandler}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 3,
          backgroundColor: theme.palette.playerBgColor.main,
          maxHeight: '80vh',
          overflow: 'auto',
        }}
      >
        <IconButton
          size="small"
          sx={{
            position: 'absolute',
            top: '-3px',
            right: '-3px',
            color: theme.palette.playerBgColor.contrastText,
          }}
          onClick={closeHandler}
        >
          <CloseOutlinedIcon />
        </IconButton>
        <Box sx={{ color: theme.palette.playerBgColor.contrastText, mb: 0 }}>
          <Typography variant="h4" textAlign="center">
            {headline}
          </Typography>
          {rewriteHtml(content)}
        </Box>
      </Paper>
    </Modal>
  );
}
