import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import usePlayerStore from '../../store/playerStore';
import validateEventAccessApi from '../../api/event/validateEventAccessApi';

type CookiesFormProps = { callback: () => void };

export default function EventAccessForm(props: CookiesFormProps) {
  // props
  const { callback } = props;

  // global state
  const { eventId, onlineStageData, setPlayerUser } = usePlayerStore();

  // state
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [cookiesError, setCookiesError] = useState('');

  const [accessCode, setAccessCode] = useState('');
  const [accessCodeError, setAccessCodeError] = useState('');

  const [accessCodeValid, setAccessCodeValid] = useState(false);

  // theme
  const theme = useTheme();

  const confirmHandler = async () => {
    //
    let accessCodeCheck = false;

    // Check if cookies are accepted
    if (cookiesAccepted) {
      setPlayerUser({ cookiesAccepted: cookiesAccepted });
    } else {
      setCookiesError(
        'Um an der Veranstatlung teilnehmen zu können, ist eine Zustimmung zu der Verwendung von Cookies erforderlich',
      );
    }

    // check accescode
    if (onlineStageData!.accessCodeRequired) {
      if (accessCode === '') {
        // setAccessCodeValid(false);
        accessCodeCheck = false;
        setAccessCodeError(
          'Um an der Veranstatlung teilnehmen zu können, ist ein Zugangscode erforderlich',
        );
      } else {
        const accessToken: string | Error = await validateEventAccessApi({
          accessCode: accessCode,
        }).catch((e) => {
          console.error(e);
          return e;
        });

        if (typeof accessToken === 'string' && accessToken !== '') {
          // setAccessCodeValid(true);
          accessCodeCheck = true;
          setAccessCodeError('');
          setPlayerUser({ accessToken: accessToken });
        } else {
          // setAccessCodeValid(false);
          accessCodeCheck = false;
          setAccessCodeError('Der eingegebene Zugangscode ist falsch');
        }
      }
    }

    //// final action
    if (
      cookiesAccepted &&
      (accessCodeCheck || !onlineStageData!.accessCodeRequired)
    ) {
      callback();
    }
  };

  //// side effects
  useEffect(() => {
    if (cookiesAccepted) setCookiesError('');
  }, [cookiesAccepted]);

  useEffect(() => {
    if (onlineStageData!.accessCodeRequired && accessCode)
      setAccessCodeError('');
  }, [accessCode]);

  return (
    <Box
      maxWidth="480px"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {onlineStageData!.accessCodeRequired && (
        <TextField
          label="Zugangscode"
          size="small"
          variant="outlined"
          value={accessCode}
          onChange={(e) => {
            setAccessCode(e.target.value);
          }}
          sx={{ mb: 2 }}
          error={Boolean(accessCodeError)}
          helperText={accessCodeError}
        />
      )}

      <FormControl fullWidth component="span" sx={{ mb: 2 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={cookiesAccepted}
              onChange={() => setCookiesAccepted((state: boolean) => !state)}
              sx={{ py: 0 }}
            />
          }
          label={'Cookies akzeptieren'}
        />

        {Boolean(cookiesError) && (
          <FormHelperText sx={{ color: theme.palette.error.main }}>
            {cookiesError}
          </FormHelperText>
        )}
      </FormControl>

      <Button onClick={confirmHandler}>Bestätigen</Button>
    </Box>
  );
}
