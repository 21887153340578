import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Stack, Switch, TextField, Typography, useTheme } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { ErCheckboxTypes } from './ErCheckbox.types';



/**
 * TextField Component
 * @param props 
 * @returns a TextField component to be directly used in react-hook-form contexts
 */
export default function ErCheckbox<T extends FieldValues>(
  props: ErCheckboxTypes<T>,
) {
  //
  const theme = useTheme()

  const {
    fullWidth,
    fieldName,
    fieldLabel,
    labelPos='start',
    labelVariant='body1',
    control,
    required,
    error,
    defaultValue = false,
    disabled,
    size,
    sx,
    onChange
  } = props;

  return (
    <Stack direction="row" alignItems="baseline">
      {/* {fieldLabel && labelPos === 'start' && <Typography variant={labelVariant}>{fieldLabel}</Typography>} */}
      <FormControl fullWidth={fullWidth} component='span' sx={sx}>
        <Controller
          name={fieldName}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            // <Switch
            //   checked={field.value}
            //   sx={sx}
            //   required={required}
            //   disabled={disabled}
            //   size={size}
            //   {...field}
            // />
            <FormControlLabel control={ <Checkbox checked={field.value} disabled={disabled} {...field} onChange={(event) => {
              field.onChange(event);
              if (onChange) {
                onChange(event);
              }}} sx={{py: 0}}/>} label={fieldLabel}/>
           
          )}
        />
        {Boolean(error) && <FormHelperText sx={{color: theme.palette.error.main}}>{error}</FormHelperText>}
      </FormControl>
      {fieldLabel && labelPos === 'end' && <Typography variant={labelVariant}>{fieldLabel}</Typography>}
    </Stack>
  );
}
