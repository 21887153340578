import React from 'react';
import { FormControl, TextField } from '@mui/material';
import { Controller, FieldValues } from 'react-hook-form';
import { ErTextFieldTypes } from './ErTextField.types';



/**
 * TextField Component
 * @param props 
 * @returns a TextField component to be directly used in react-hook-form contexts
 */
export default function ErTextField<T extends FieldValues>(
  props: ErTextFieldTypes<T>,
) {
  const {
    fullWidth,
    fieldName,
    fieldLabel,
    control,
    required,
    variant,
    error,
    defaultValue = '',
    type,
    multiline,
    rows,
    maxRows,
    readOnly,
    startAdornment,
    disabled,
    autoComplete,
    placeholder,
    sx,
    size,
    color,
  } = props;

  return (
    <FormControl fullWidth={fullWidth}>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <TextField
            sx={sx}
            color={color}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            label={fieldLabel}
            autoComplete={autoComplete}
            variant={variant}
            size={size}
            type={type}
            multiline={multiline}
            rows={rows}
            maxRows={maxRows}
            error={Boolean(error)}
            helperText={error}
            InputProps={{
              readOnly: readOnly,
              startAdornment: startAdornment,
            }}
            {...field}
          />
        )}
      />
    </FormControl>
  );
}
