import React, { useCallback, useEffect, useRef, useState } from "react";
if (typeof window !== "undefined") {
  import("./styles.css");
}
import { ErSwipteToDeleteTypes } from "./ErSwipeToDelete.types";

// export interface Props {
//   onDelete: Function;
//   onDeleteConfirm?: Function;
//   deleteComponent?: React.ReactNode;
//   disabled?: boolean;
//   height?: number;
//   transitionDuration?: number;
//   deleteWidth?: number;
//   deleteThreshold?: number;
//   showDeleteAction?: boolean;
//   deleteColor?: string;
//   deleteText?: string;
//   className?: string;
//   id?: string;
//   rtl?: boolean;
//   children?: React.ReactNode;
// }

const cursorPosition = (event: any) => {
  if (event?.touches?.[0]?.clientX) return event.touches[0].clientX;
  if (event?.clientX) return event?.clientX;
  if (event?.nativeEvent?.touches?.[0]?.clientX) return event.nativeEvent.touches[0].clientX;
  return event?.nativeEvent?.clientX;
};

const ErSwipeToDelete = ({
  onDelete,
  onDeleteConfirm,
  deleteComponent,
  disabled = false,
  height = 50,
  transitionDuration = 250,
  deleteWidth = 75,
  deleteThreshold = 75,
  showDeleteAction = true,
  deleteColor = "rgba(252, 58, 48, 1.00)",
  deleteText = "Delete",
  className = "",
  id = "",
  rtl = false,
  children,
}: ErSwipteToDeleteTypes) => {
  const [touching, setTouching] = useState(false);
  const [translate, setTranslate] = useState(0);
  const [deleting, setDeleting] = useState(false);

  const [isDisabled, setIsDisabled] = useState(disabled)
  
  const startTouchPosition = useRef(0);
  const initTranslate = useRef(0);
  const container = useRef<HTMLDivElement>(null);
  const containerWidth: number = container.current?.getBoundingClientRect().width || 0;
  const deleteWithoutConfirmThreshold: number = containerWidth * (deleteThreshold / 100);

  const onStart = useCallback(
    (event: React.TouchEvent | React.MouseEvent) => {
      if (isDisabled) return;
      if (touching) return;
      startTouchPosition.current = cursorPosition(event);
      initTranslate.current = translate;
      setTouching(true);
    },
    [isDisabled, touching, translate]
  );

  useEffect(() => {
    setIsDisabled(disabled);
    if(disabled) {
      setTranslate(() => 0);
      initTranslate.current = 0};
  },[disabled])

  useEffect(() => {
    const root = container.current;
    root?.style.setProperty("--rstdiHeight", height + "px");
    root?.style.setProperty("--rstdiTransitionDuration", transitionDuration + "ms");
    root?.style.setProperty("--rstdiIsRtl", rtl ? "1" : "-1");
    root?.style.setProperty("--rstdiDeleteColor", deleteColor);
    root?.style.setProperty("--rstdiDeleteWidth", deleteWidth + "px");
  }, [deleteColor, deleteWidth, height, rtl, transitionDuration]);

  useEffect(() => {
    const root = container.current;
    
      root?.style.setProperty("--rstdiTranslate", translate * (rtl ? -1 : 1) + "px");
      const shiftDelete = -translate >= deleteWithoutConfirmThreshold;
      root?.style.setProperty(
        `--rstdiButtonMargin${rtl ? "Right" : "Left"}`,
        (shiftDelete ? containerWidth + translate : containerWidth - deleteWidth) + "px");
    
  }, [translate, deleteWidth, containerWidth, rtl, deleteWithoutConfirmThreshold]);

  
  const onMove = useCallback(
    function (event: TouchEvent | MouseEvent) {
      if(!isDisabled) {
        if (!touching) return;
        if (!rtl && cursorPosition(event) > startTouchPosition.current - initTranslate.current)
          return setTranslate(0);
        if (rtl && cursorPosition(event) < startTouchPosition.current - initTranslate.current)
          return setTranslate(0);
        setTranslate(cursorPosition(event) - startTouchPosition.current + initTranslate.current);
      } else {
        startTouchPosition.current = cursorPosition(event);
        setTranslate(() => 0);
        initTranslate.current = 0;
      }
    },
    [rtl, touching, isDisabled]
  );
  

  const onMouseMove = useCallback(
    function (event: MouseEvent): any {
      if(!isDisabled) {
        onMove(event);
      } else {
        
        
        startTouchPosition.current = cursorPosition(event)
      }
    },
    [onMove,isDisabled]
  );


  const onTouchMove = useCallback(
    function (event: TouchEvent): any {
      if(!isDisabled) {
        onMove(event);
      } else {
        startTouchPosition.current = cursorPosition(event)
      }
    },
    [onMove,isDisabled]
  );

  const onDeleteConfirmed = useCallback(() => {
    setDeleting(() => true);
    window.setTimeout(onDelete, transitionDuration);
  }, [onDelete, transitionDuration]);

  const onDeleteCancel = useCallback(() => {
    setTouching(() => false);
    setTranslate(() => 0);
    setDeleting(() => false);
    startTouchPosition.current = 0;
    initTranslate.current = 0;
  }, [onDelete, transitionDuration]);

  const onDeleteClick = useCallback(() => {
    if (onDeleteConfirm) {
      onDeleteConfirm(onDeleteConfirmed, onDeleteCancel);
    } else {
      onDeleteConfirmed();
    }
  }, [onDeleteConfirm, onDeleteConfirmed]);

  const onMouseUp = useCallback(
    function () {
      startTouchPosition.current = 0;
      const acceptableMove = -deleteWidth * 0.7;
      const showDelete = showDeleteAction ? (rtl ? -1 : 1) * translate < acceptableMove : false;
      const notShowDelete = showDeleteAction ? (rtl ? -1 : 1) * translate >= acceptableMove : true;
      const deleteWithoutConfirm = (rtl ? 1 : -1) * translate >= deleteWithoutConfirmThreshold;
      
      if (deleteWithoutConfirm) {
        setTranslate(() => -containerWidth);
      } else if (notShowDelete) {
        setTranslate(() => 0);
      } else if (showDelete && !deleteWithoutConfirm) {
        setTranslate(() => (rtl ? 1 : -1) * deleteWidth);
      }
      setTouching(() => false);
      if (deleteWithoutConfirm) onDeleteClick();
    },
    [containerWidth, deleteWidth, deleteWithoutConfirmThreshold, onDeleteClick, rtl, translate]
  );

  useEffect(() => {
    if (touching) {
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("touchmove", onTouchMove);
      window.addEventListener("mouseup", onMouseUp);
      window.addEventListener("touchend", onMouseUp);
    } else {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("touchmove", onTouchMove);
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("touchend", onMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("touchmove", onTouchMove);
      window.removeEventListener("mouseup", onMouseUp);
      window.removeEventListener("touchend", onMouseUp);
    };
  }, [onMouseMove, onMouseUp, onTouchMove, touching]);

  return (
    <div id={id} className={`rstdi${deleting ? " deleting" : ""} ${className}`} ref={container}>
      <div className={`delete${deleting ? " deleting" : ""}`}>
        <button onClick={onDeleteClick} onPointerUp={onDeleteClick}>{deleteComponent ? deleteComponent : deleteText}</button>
      </div>
      <div
        className={`content${deleting ? " deleting" : ""}${!touching ? " transition" : ""}`}
        onMouseDown={onStart}
        onTouchStart={onStart}>
        {children}
      </div>
    </div>
  );
};

export default ErSwipeToDelete;

// import React, { useCallback, useEffect, useRef, useState } from "react";

// export interface Props {
//   onDelete: Function;
//   onDeleteConfirm?: Function;
//   deleteComponent?: React.ReactNode;
//   disabled?: boolean;
//   height?: number;
//   transitionDuration?: number;
//   deleteWidth?: number;
//   deleteThreshold?: number;
//   showDeleteAction?: boolean;
//   deleteColor?: string;
//   deleteText?: string;
//   className?: string;
//   id?: string;
//   rtl?: boolean;
//   children?: React.ReactNode;
// }

// const cursorPosition = (event: any) => {
//   if (event?.touches?.[0]?.clientX) return event.touches[0].clientX;
//   if (event?.clientX) return event?.clientX;
//   if (event?.nativeEvent?.touches?.[0]?.clientX) return event.nativeEvent.touches[0].clientX;
//   return event?.nativeEvent?.clientX;
// };

// const ErSwipeToDelete = ({
//   onDelete,
//   onDeleteConfirm,
//   deleteComponent,
//   disabled = false,
//   height = 50,
//   transitionDuration = 250,
//   deleteWidth = 75,
//   deleteThreshold = 75,
//   showDeleteAction = true,
//   deleteColor = "rgba(252, 58, 48, 1.00)",
//   deleteText = "Delete",
//   className = "",
//   id = "",
//   rtl = false,
//   children,
// }: Props) => {
//   const [touching, setTouching] = useState(false);
//   const [translate, setTranslate] = useState(0);
//   const [deleting, setDeleting] = useState(false);
//   const [isDisabled, setIsDisabled] = useState(disabled);
  
//   const startTouchPosition = useRef(0);
//   const initTranslate = useRef(0);
//   const container = useRef<HTMLDivElement>(null);
//   const containerWidth: number = container.current?.getBoundingClientRect().width || 0;
//   const deleteWithoutConfirmThreshold: number = containerWidth * (deleteThreshold / 100);

//   const onStart = useCallback(
//     (event: React.TouchEvent | React.MouseEvent) => {
//       if (isDisabled) return;
//       if (touching) return;
//       startTouchPosition.current = cursorPosition(event);
//       initTranslate.current = translate;
//       setTouching(true);
//     },
//     [isDisabled, touching, translate]
//   );

//   useEffect(() => {
//     setIsDisabled(disabled);
//     if(disabled) {
//       setTranslate(() => 0);
//       initTranslate.current = 0};
//   },[disabled]);

//   const styles = {
//     rstdi: {
//       '--rstdiHeight': `${height}px`,
//       '--rstdiTransitionDuration': `${transitionDuration}ms`,
//       '--rstdiTranslate': `${translate * (rtl ? -1 : 1)}px`,
//       '--rstdiIsRtl': rtl ? '1' : '-1',
//       '--rstdiDeleteColor': deleteColor,
//       '--rstdiDeleteWidth': `${deleteWidth}px`,
//       '--rstdiButtonMarginRight': '0px',
//       '--rstdiButtonMarginLeft': '0px',
//       width: 'auto',
//       position: 'relative' as 'relative',
//       boxSizing: 'border-box' as 'border-box',
//       overflow: 'hidden' as 'hidden',
//       height: `var(--rstdiHeight)`,
//       maxHeight: `calc(var(--rstdiHeight) + 10px)`,
//       transition: deleting ? `all var(--rstdiTransitionDuration) ease-out` : 'none',
//     },
//     content: {
//       height: '100%',
//       width: 'auto',
//       position: 'relative' as 'relative',
//       transform: `translateX(var(--rstdiTranslate))`,
//       transition: !touching ? `transform var(--rstdiTransitionDuration) ease-out` : 'none',
//     },
//     delete: {
//       position: 'absolute' as 'absolute',
//       right: '0',
//       height: '100%',
//       width: '100%',
//       top: '0',
//       background: `var(--rstdiDeleteColor)`,
//       fontWeight: 400,
//       display: 'inline-flex',
//       justifyContent: 'flex-start',
//       alignItems: 'center',
//     },
//     deleteButton: {
//       width: `var(--rstdiDeleteWidth)`,
//       transition: `margin transform var(--rstdiTransitionDuration) ease-in-out`,
//       marginLeft: `var(--rstdiButtonMarginLeft)`,
//       marginRight: `var(--rstdiButtonMarginRight)`,
//       textAlign: 'center' as 'center',
//       height: '100%',
//       background: 'transparent',
//       border: 'none',
//       color: 'white',
//       fontSize: '1rem',
//       cursor: 'pointer',
//     },
//   };

//   const onMove = useCallback(
//     function (event: TouchEvent | MouseEvent) {
//       if (!isDisabled) {
//         if (!touching) return;
//         if (!rtl && cursorPosition(event) > startTouchPosition.current - initTranslate.current)
//           return setTranslate(0);
//         if (rtl && cursorPosition(event) < startTouchPosition.current - initTranslate.current)
//           return setTranslate(0);
//         setTranslate(cursorPosition(event) - startTouchPosition.current + initTranslate.current);
//       } else {
//         startTouchPosition.current = cursorPosition(event);
//         setTranslate(() => 0);
//         initTranslate.current = 0;
//       }
//     },
//     [rtl, touching, isDisabled]
//   );

//   const onMouseMove = useCallback(
//     function (event: MouseEvent): any {
//       if (!isDisabled) {
//         onMove(event);
//       } else {
//         startTouchPosition.current = cursorPosition(event);
//       }
//     },
//     [onMove, isDisabled]
//   );

//   const onTouchMove = useCallback(
//     function (event: TouchEvent): any {
//       if (!isDisabled) {
//         onMove(event);
//       } else {
//         startTouchPosition.current = cursorPosition(event);
//       }
//     },
//     [onMove, isDisabled]
//   );

//   const onDeleteConfirmed = useCallback(() => {
//     setDeleting(() => true);
//     window.setTimeout(onDelete, transitionDuration);
//   }, [onDelete, transitionDuration]);

//   const onDeleteCancel = useCallback(() => {
//     setTouching(() => false);
//     setTranslate(() => 0);
//     setDeleting(() => false);
//     startTouchPosition.current = 0;
//     initTranslate.current = 0;
//   }, [onDelete, transitionDuration]);

//   const onDeleteClick = useCallback(() => {
//     if (onDeleteConfirm) {
//       onDeleteConfirm(onDeleteConfirmed, onDeleteCancel);
//     } else {
//       onDeleteConfirmed();
//     }
//   }, [onDeleteConfirm, onDeleteConfirmed]);

//   const onMouseUp = useCallback(
//     function () {
//       startTouchPosition.current = 0;
//       const acceptableMove = -deleteWidth * 0.7;
//       const showDelete = showDeleteAction ? (rtl ? -1 : 1) * translate < acceptableMove : false;
//       const notShowDelete = showDeleteAction ? (rtl ? -1 : 1) * translate >= acceptableMove : true;
//       const deleteWithoutConfirm = (rtl ? 1 : -1) * translate >= deleteWithoutConfirmThreshold;
      
//       if (deleteWithoutConfirm) {
//         setTranslate(() => -containerWidth);
//       } else if (notShowDelete) {
//         setTranslate(() => 0);
//       } else if (showDelete && !deleteWithoutConfirm) {
//         setTranslate(() => (rtl ? 1 : -1) * deleteWidth);
//       }
//       setTouching(() => false);
//       if (deleteWithoutConfirm) onDeleteClick();
//     },
//     [containerWidth, deleteWidth, deleteWithoutConfirmThreshold, onDeleteClick, rtl, translate]
//   );

//   useEffect(() => {
//     if (touching) {
//       window.addEventListener("mousemove", onMouseMove);
//       window.addEventListener("touchmove", onTouchMove);
//       window.addEventListener("mouseup", onMouseUp);
//       window.addEventListener("touchend", onMouseUp);
//     } else {
//       window.removeEventListener("mousemove", onMouseMove);
//       window.removeEventListener("touchmove", onTouchMove);
//       window.removeEventListener("mouseup", onMouseUp);
//       window.removeEventListener("touchend", onMouseUp);
//     }
//     return () => {
//       window.removeEventListener("mousemove", onMouseMove);
//       window.removeEventListener("touchmove", onTouchMove);
//       window.removeEventListener("mouseup", onMouseUp);
//       window.removeEventListener("touchend", onMouseUp);
//     };
//   }, [onMouseMove, onMouseUp, onTouchMove, touching]);

//   return (
//     <div
//       id={id}
//       className={`rstdi${deleting ? " deleting" : ""} ${className}`}
//       ref={container}
//       style={styles.rstdi}
//     >
//       <div className={`delete${deleting ? " deleting" : ""}`} style={styles.delete}>
//         <button onClick={onDeleteClick} onPointerUp={onDeleteClick} style={styles.deleteButton}>
//           {deleteComponent ? deleteComponent : deleteText}
//         </button>
//       </div>
//       <div
//         className={`content${deleting ? " deleting" : ""}${!touching ? " transition" : ""}`}
//         onMouseDown={onStart}
//         onTouchStart={onStart}
//         style={styles.content}
//       >
//         {children}
//       </div>
//     </div>
//   );
// };

// export default ErSwipeToDelete;
