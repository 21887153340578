import { MutableRefObject, useEffect, useState } from 'react';
import usePlayerStore from '../store/playerStore';
import { useFeedback } from '../Components/Feedback/FeedbackContext';
import useLiveStore from '../store/liveStore';
import { useLocation, useNavigate } from 'react-router-dom';
import useInterval from './useInterval';

export default function useViewControl() {
  // player store
  const {
    playerUi,
    registrationData,
    setPlayerUser,
    playerUser,
    onlineStageData,
    isPreview,
    selectedSession,
  } = usePlayerStore();

  const { liveData } = useLiveStore();

  const firstLiveSessionObj = useLiveStore(
    (state) => Object.values(state.sessions)[0],
  );
  const selectedLiveSessionObj = useLiveStore(
    (state) => state.sessions[selectedSession!],
  );

  // state
  const [vcInit, setVcInit] = useState(false);
  const [currentView, setCurrentView] = useState('/');
  const [registrationOpen, setRegistrationOpen] = useState(
    registrationData
      ? new Date().getTime() >= registrationData.registrationStarts.getTime()
      : false,
  );
  const [lobbyOpen, setLobbyOpen] = useState(
    new Date().getTime() >= onlineStageData!.starttimeLobby.getTime(),
  );
  const [userValid, setUserValid] = useState(false);

  // feedback
  const { setFeedback } = useFeedback();

  // navigation
  const navigate = useNavigate();
  const location = useLocation();

  //// VIEW CONTROL LOGIC
  // helper - getDayIndex
  const getDayIndex = (startDate: Date, duration: number, checkDate: Date) => {
    const start = new Date(startDate);
    start.setHours(0, 0, 0, 0);

    const end = new Date(start);
    end.setDate(start.getDate() + duration - 1);
    end.setHours(0, 0, 0, 0);

    const check = new Date(checkDate);
    check.setHours(0, 0, 0, 0);

    // Überprüfe, ob das checkDate innerhalb der Zeitspanne liegt
    if (check >= start && check <= end) {
      // Berechne den Tag innerhalb der Zeitspanne
      const dayInRange = Math.floor(
        (check.getTime() - start.getTime()) / (1000 * 60 * 60 * 24),
      );
      return dayInRange;
    } else {
      return -1; // Datum liegt nicht innerhalb der Zeitspanne
    }
  };

  // check if event is closed
  const checkClosed = () => {
    // current date normalized
    const todayNormalized = new Date(new Date().setHours(0, 0, 0, 0));

    // check the events (static) online state
    const onlineStateArray = playerUi!.onlineStagePlayState;

    const todaysState = onlineStateArray?.find((state: any) => {
      return new Date(state.day.iso).getTime() === todayNormalized.getTime();
    });

    if (todaysState && todaysState.state === 'ended') {
      return true;
    }

    // check the live (dynamic) state
    const liveStateArray = liveData?.eventPlayState;

    const todaysLiveState = liveStateArray?.find((state: any) => {
      return new Date(state.day.iso).getTime() === todayNormalized.getTime();
    });

    if (todaysLiveState && todaysLiveState.state === 'ended') {
      return true;
    }

    // check if event end date has passed
    const eventEnd = new Date(playerUi!.eventStartDateTime);
    eventEnd.setDate(eventEnd.getDate() + playerUi!.eventDuration - 1);

    if (eventEnd.setHours(23, 59, 59, 950) < todayNormalized.getTime()) {
      return true;
    }

    // if single session, event ended when the session ended
    if (!playerUi!.multiSession) {
      // const liveSessionState = liveDataSession?.[0].status;
      const liveSessionState = firstLiveSessionObj?.status;

      if (liveSessionState === 'ended') return true;
    }

    return false;
  };

  // check if session live is available for user
  const checkLive = () => {
    // current date normalized
    const todayNormalized = new Date(new Date().setHours(0, 0, 0, 0));

    // if registration, we need a valid user
    if (registrationData && !userValid) return false;

    // cookies must be accepted
    if (!playerUser?.cookiesAccepted) return false;

    // check if access token is required & if we have one
    if (onlineStageData?.accessCodeRequired && !playerUser?.accessToken)
      return false;

    // single- or multisession
    if (playerUi!.multiSession) {
      // if multisession...
      // event has to be started (access to lobby)
      const liveStateArray = liveData?.eventPlayState;

      const todaysLiveState = liveStateArray?.find((state: any) => {
        return new Date(state.day.iso).getTime() === todayNormalized.getTime();
      });

      // get the automatic opening state
      const dayIndex = getDayIndex(
        playerUi!.eventStartDateTime,
        playerUi!.eventDuration,
        new Date(),
      );

      const today = new Date();

      const openingTime = new Date(
        onlineStageData?.openingTimes?.[dayIndex]?.starttime,
      );

      if (openingTime) {
        openingTime.setDate(today.getDate());
        openingTime.setFullYear(today.getFullYear());
      }

      if (
        (!todaysLiveState || todaysLiveState.state !== 'play') &&
        onlineStageData?.autoStartEvent &&
        (!onlineStageData?.openingTimes?.[dayIndex] ||
          openingTime.getTime() > today.getTime())
      ) {
        return false;
      }

      // a session has to be selected
      if (!selectedSession || selectedSession === '') return false;

      // the session has to be started
      // const selectedSessionData = liveDataSession.find(
      //   (ses: any) => ses.id === selectedSession,
      // );

      const selectedSessionData = selectedLiveSessionObj;

      // session data is not available
      if (!selectedSessionData) return false;

      if (selectedSessionData.status !== 'play') return false;
    } else {
      if (firstLiveSessionObj.status !== 'play') return false;
    }

    return true;
  };

  // check if sessionWelcome is available for user
  const checkSessionWelcome = () => {
    // current date normalized
    const todayNormalized = new Date(new Date().setHours(0, 0, 0, 0));

    // only for multi session
    if (!playerUi!.multiSession) return false;

    // if registration, we need a valid user
    if (registrationData && !userValid) return false;

    // cookies must be accepted
    if (!playerUser?.cookiesAccepted) return false;

    // check if we need access token is required & if we have one
    if (onlineStageData?.accessCodeRequired && !playerUser?.accessToken)
      return false;

    // check if lobby is open (event play state is set or event auto entry time is reached)
    // get the live (dynamic) state
    const liveStateArray = liveData?.eventPlayState;

    const todaysLiveState = liveStateArray?.find((state: any) => {
      return new Date(state.day.iso).getTime() === todayNormalized.getTime();
    });

    // get the automatic opening state
    const dayIndex = getDayIndex(
      playerUi!.eventStartDateTime,
      playerUi!.eventDuration,
      new Date(),
    );

    const today = new Date();

    const openingTime = new Date(
      onlineStageData?.openingTimes?.[dayIndex]?.starttime,
    );

    if (openingTime) {
      openingTime.setDate(today.getDate());
      openingTime.setFullYear(today.getFullYear());
    }

    if (
      (!todaysLiveState || todaysLiveState.state !== 'play') &&
      onlineStageData?.autoStartEvent &&
      (!onlineStageData?.openingTimes?.[dayIndex] ||
        openingTime.getTime() > today.getTime())
    ) {
      return false;
    }

    // check if we have a selected session
    if (!selectedSession || selectedSession === '') return false;

    // check if the selected session already ended
    const selectedSessionData = selectedLiveSessionObj;

    // session data is not available
    if (!selectedSessionData) return false;

    if (selectedSessionData.status !== 'stop') return false;

    return true;
  };

  // check if lobby is available for user
  const checkLobby = () => {
    // current date normalized
    const todayNormalized = new Date(new Date().setHours(0, 0, 0, 0));

    // Lobby only for multi session events
    if (!playerUi!.multiSession) return false;

    // if registration, we need a valid user
    if (registrationData && !userValid) return false;

    // cookies must be accepted
    if (!playerUser?.cookiesAccepted) return false;

    // check if access token is required & if we have one
    if (onlineStageData?.accessCodeRequired && !playerUser?.accessToken)
      return false;

    // check if lobby is open (event play state is set or event auto entry time is reached)
    // get the live (dynamic) state
    const liveStateArray = liveData?.eventPlayState;

    const todaysLiveState = liveStateArray?.find((state: any) => {
      return new Date(state.day.iso).getTime() === todayNormalized.getTime();
    });

    console.log('##!##!#-todaysLiveState', todaysLiveState);
    // no live state, no lobby
    if (!todaysLiveState) return false;

    // get the automatic opening state
    const dayIndex = getDayIndex(
      playerUi!.eventStartDateTime,
      playerUi!.eventDuration,
      new Date(),
    );

    const today = new Date();

    const openingTime = new Date(
      onlineStageData?.openingTimes?.[dayIndex]?.starttime,
    );

    if (openingTime) {
      openingTime.setDate(today.getDate());
      openingTime.setFullYear(today.getFullYear());
    }

    if (
      (!todaysLiveState || todaysLiveState.state !== 'play') &&
      onlineStageData?.autoStartEvent &&
      (!onlineStageData?.openingTimes?.[dayIndex] ||
        openingTime.getTime() > today.getTime())
    ) {
      return false;
    }

    return true;
  };

  // redirect checks
  const redirectHandler = () => {
    if (!isPreview) {
      const urlSearchParams = new URLSearchParams(location.search);

      // CLOSED VIEW - check if event ended
      if (checkClosed()) {
        // if event is already closed or get's closed by admin action
        // redirect to view CLOSED
        location.pathname !== '/closed' &&
          navigate('/closed' + location.search);
        setCurrentView('closed');
        return 'closed';
      }

      // LIVE VIEW
      if (checkLive()) {
        location.pathname !== '/live' && navigate('/live' + location.search);
        setCurrentView('live');
        return 'live';
      }

      // SESSION WELCOME
      if (checkSessionWelcome()) {
        location.pathname !== '/sessionWelcome' &&
          navigate('/sessionWelcome' + location.search);
        setCurrentView('sessionWelcome');
        return 'sessionWelcome';
      }

      console.log('#!#!-LOBBY-CHECK', playerUi!.multiSession);
      // LOBBY VIEW
      if (checkLobby()) {
        location.pathname !== '/lobby' && navigate('/lobby' + location.search);
        setCurrentView('lobby');
        return 'lobby';
      }

      // WELCOME VIEW
      if (
        registrationData &&
        //   playerUser?.userConfirmed &&
        userValid &&
        playerUser?.cookiesAccepted &&
        (!onlineStageData?.accessCodeRequired || playerUser?.accessToken)
      ) {
        location.pathname !== '/welcome' &&
          navigate('/welcome' + location.search);
        setCurrentView('welcome');
        return 'welcome';
      }

      // REGISTRATION / CONFIRMATION
      if (registrationData) {
        if (urlSearchParams.get('uct') || playerUser?.uct) {
          // CONFIRMATION VIEW - user wants to confirm registration / invitation
          // uct = UserConfirmationToken

          if (urlSearchParams.get('uct')) {
            setPlayerUser({ uct: urlSearchParams.get('uct') });
            urlSearchParams.delete('uct');
          }

          // store utc in local
          location.pathname !== '/confirm' &&
            navigate('/confirm?' + urlSearchParams.toString());
          setCurrentView('confirm');
          return 'confirm';
        } else if (registrationOpen && !userValid) {
          // REGISTRATION VIEW - user needs to register
          location.pathname !== '/registration' &&
            navigate('/registration' + location.search);
          setCurrentView('registration');
          return 'registration';
        }
      }

      // DEFAULT VIEW
      location.pathname !== '/' && navigate('/' + location.search);
      setCurrentView('/');
      return '/';
    }
  };

  //// check for dates
  // check if registration is open
  useInterval(() => {
    if (
      registrationData &&
      !lobbyOpen &&
      new Date().getTime() >= registrationData?.registrationStarts.getTime()
    ) {
      setRegistrationOpen(true);
      redirectHandler();
    }
  }, 1000);

  // check if lobby is open
  useInterval(() => {
    if (
      playerUi!.multiSession &&
      !registrationOpen &&
      new Date().getTime() >= onlineStageData!.starttimeLobby.getTime()
    ) {
      setLobbyOpen(true);
      redirectHandler();
    }
  }, 1000);

  //// side effects
  // INITIAL REDIRECT
  useEffect(() => {
    if (playerUser) {
      if (registrationData && registrationData.confirmEmail) {
        // handle user emailConfirm
        if (playerUser.userMailConfirmed) {
          setUserValid(true);
        }
      } else {
        setUserValid(true);
      }
    }
    redirectHandler();
  }, []);

  // init view control needed???
  useEffect(() => {
    if (playerUi) {
      redirectHandler();
      setVcInit(true);
    } else {
      setFeedback({ type: 'loading' });
    }
  }, [playerUi]);

  // check if we have a valid user
  useEffect(() => {
    if (playerUser && playerUser.firstname) {
      if (registrationData && registrationData.confirmEmail) {
        // handle user emailConfirm
        if (playerUser.userMailConfirmed) {
          setUserValid(true);
        }
      } else {
        setUserValid(true);
        if (playerUser?.cookiesAccepted) redirectHandler();
      }
    }
  }, [playerUser]);

  // check if we need to redirect if valid user changes
  useEffect(() => {
    if (userValid) redirectHandler();
  }, [userValid]);

  // check for changes in live data
  useEffect(() => {
    //!!! be more specific on when to update the redirect
    redirectHandler();
  }, [liveData]);

  // check for changes in selected session
  useEffect(() => {
    //!!! be more specific on when to update the redirect
    redirectHandler();
  }, [selectedSession]);

  return { vcInit: vcInit, currentView: currentView };
}
