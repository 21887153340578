import ActorRejected from '../svg/ActorList/actorRejected.svg';
import { createSvgIcon } from '@mui/material';
import React from 'react';

const props = {
  version: '1.1',
  width: '24px',
  height: '24px',
  viewBox: '0 0 512 512',
};

export const ActorRejectedIcon = createSvgIcon(
  <ActorRejected {...(props as any)} />,
  'Trim',
);
